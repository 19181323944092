/* eslint-disable */
<template>
	<header id="header">
		<div class="container-fluid header">
			<div class="row align-center">
				<div class="col-md-6 text-left">
					<div class="logo">
						<nav class="navbar navbar-expand-lg navbar-light">
							<button class="navbar-toggler" type="button" data-toggle="collapse"
								data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
								aria-expanded="false" aria-label="Toggle navigation">
								<img :src="require('@/assets/img/menu.svg')">
							</button>
							<a class="navbar-brand" :href="route">
								<img :src="require('@/assets/img/mccain_logo.png')">
							</a>
						</nav>
					</div>
				</div>
				<div class="col-md-6 text-right">
					<ul class="navbar-desktop">
            <li>
              <a class="menu-item" :href="route">
                {{ $t('header.home') }}
              </a>
            </li>
            <!-- <li>
              <router-link class="menu-item" :to="route + 'contacto'">Contáctenos</router-link>
            </li> -->
            <li v-if="this.name">
              <a href="javascript:void(0)">{{ this.name }}</a>
            </li>
            <li>
              <a @click="logout()" href="javascript:void(0)">
                {{ !signOut ? $t('header.signOut') : $t('header.signingOut') }}
              </a>
            </li>
          </ul>
				</div>
			</div>
		</div>
		<div class="main-elements">
			<div class="container">
				<div class="row">
					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav mr-auto">
							<li class="nav-item active">
								<a :href="route" class="nav-link">Home</a>
							</li>
							<!-- <li class="nav-item">
								<router-link :to="route + 'contacto'" class="nav-link">Contáctenos</router-link>
							</li> -->
							<li class="nav-item">
								<a class="nav-link" href="javascript:void(0)">{{this.name}}</a>
							</li>
							<li class="nav-item mt-5 mb-5">
								<a @click="logout()" class="nav-link" href="javascript:void(0)">
                  {{ !signOut ? 'Cerrar Sesión' : 'Cerrando sesión ...' }}
                </a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</header>
	
</template>
<script>
import {route} from "@/enviroment"
import axios from 'axios'

export default {
  name: "HeaderMcCain",
  props: {},
  data() {
    return {
      route: route,
      name: "",
      signOut: false
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      const profile = sessionStorage.getItem('mccain_service_user_profile');
      if (profile) {
        const data = JSON.parse(profile);
        this.countryProfile = data.address.countryName;
        switch (this.countryProfile) {
          case 'Argentina':
          default:
            this.name = data.email;
            break;
          case 'Calatin':
            this.name = data.firstname ?? '' + ' ' + data.lastname ?? '';
            break;
          case 'Mexico':
            this.name = data.firstname;
            break;
        }
      }
    },
    logout() {
      if (this.signOut) return;
      this.signOut = true;
      axios.get(
          '/auth/logout'
      ).then(() => {
        sessionStorage.removeItem('mccain_service_country');
        sessionStorage.removeItem('mccain_service_user_profile');
        sessionStorage.removeItem('mccain_service_expire_session');
        location.href = route + 'login';
      }).catch((error) => {
        console.log(error)
        this.signOut = false;
      });
    },
  },
}
</script>