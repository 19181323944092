<template>
  <div class="pedido-listado">
    <section class="pedido-steps">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h5 class="pull-left">{{ $t('tdc.form.title') }}</h5>
              <router-link :to="route + 'tdc'">
                <img :src="require('@/assets/img/backspace.svg')" alt="backspace">
              </router-link>
            </div>
            <p v-if="!submitted && createdRequests.length === 0" class="subtitulo">
              {{ $t('tdc.form.subtitle') }}
            </p>
            <p v-if="submitted && createdRequests.length > 0" class="subtitulo">
              {{ $t('tdc.form.submitted') }}
            </p>
          </div>
        </div>
        <form v-if="!submitted && createdRequests.length === 0" class="form-filter mt-3">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.market') }}</label>
                <VueMultiselect
                    v-model="filter.market"
                    :options="markets.options"
                    :track-by="'market'"
                    :label="'market'"
                    :multiple="false"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="markets.loading"
                    :disabled="selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="!filter.market && errors?.markets">{{ errors.markets }}</span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.divisions') }}</label>
                <VueMultiselect
                    v-model="filter.divisions"
                    :options="divisions.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="skus.loading"
                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="filter.divisions.length === 0 && errors?.divisions">
                  {{ errors.divisions }}
                </span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.categories') }}</label>
                <VueMultiselect
                    v-model="filter.categories"
                    :options="categories.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="skus.loading"
                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="filter.categories.length === 0 && errors?.categories">
                  {{ errors.categories }}
                </span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.subcategories') }}</label>
                <VueMultiselect
                    v-model="filter.subcategories"
                    :options="subcategories.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="skus.loading"
                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="filter.subcategories.length === 0 && errors?.subcategories">
                  {{ errors.subcategories }}
                </span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.families') }}</label>
                <VueMultiselect
                    v-model="filter.families"
                    :options="families.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="skus.loading"
                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="filter.families.length === 0 && errors?.families">
                  {{ errors.families }}
                </span>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.formTypes') }}</label>
                <VueMultiselect
                    v-model="filter.formTypes"
                    :options="formTypes.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="skus.loading"
                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"
                    :placeholder="$t('global.placeholder_select')"
                />
                <span class="required-span" v-if="filter.formTypes.length === 0 && errors?.formTypes">
                  {{ errors.formTypes }}
                </span>
              </div>
            </div>
<!--            <div class="col-md-6">-->
<!--              <div class="form-group">-->
<!--                <label>{{ $t('tdc.sku') }}</label>-->
<!--                <VueMultiselect-->
<!--                    v-model="filter.skus"-->
<!--                    :options="skus.options"-->
<!--                    :custom-label="formatSkuLabel"-->
<!--                    :multiple="true"-->
<!--                    :taggable="true"-->
<!--                    :searchable="true"-->
<!--                    :close-on-select="true"-->
<!--                    :show-labels="false"-->
<!--                    :loading="skus.loading"-->
<!--                    :disabled="markets.loading || !filter.market || selectedRequests.length > 0"-->
<!--                    :placeholder="$t('global.placeholder_select')"-->
<!--                    @remove="handleRemove"-->
<!--                />-->
<!--                <span class="required-span" v-if="filter.skus.length === 0 && errors?.skus">-->
<!--                  {{ errors.skus }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-md-3">-->
<!--              <div class="form-group">-->
<!--                <label>{{ $t('tdc.countries') }}</label>-->
<!--                <VueMultiselect-->
<!--                    v-model="filter.countries"-->
<!--                    :options="countries.options"-->
<!--                    :multiple="true"-->
<!--                    :taggable="true"-->
<!--                    :searchable="true"-->
<!--                    :close-on-select="true"-->
<!--                    :show-labels="false"-->
<!--                    :loading="skus.loading"-->
<!--                    :disabled="markets.loading || !filter.market || filter.skus.length === 0 || selectedRequests.length > 0"-->
<!--                    :placeholder="$t('global.placeholder_select')"-->
<!--                />-->
<!--                <span class="required-span"-->
<!--                      v-if="filter.countries.length === 0 && errors?.countries">{{ errors.countries }}</span>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="col-md-3">-->
<!--              <div class="form-group">-->
<!--                <label>{{ $t('tdc.sources') }}</label>-->
<!--                <VueMultiselect-->
<!--                    v-model="filter.sources"-->
<!--                    :options="sources.options"-->
<!--                    :multiple="true"-->
<!--                    :taggable="true"-->
<!--                    :searchable="true"-->
<!--                    :close-on-select="true"-->
<!--                    :show-labels="false"-->
<!--                    :loading="skus.loading"-->
<!--                    :disabled="markets.loading || !filter.market || filter.skus.length === 0 || filter.countries.length === 0 || selectedRequests.length > 0"-->
<!--                    :placeholder="$t('global.placeholder_select')"-->
<!--                />-->
<!--                <span class="required-span" v-if="filter.sources.length === 0 && errors?.sources">-->
<!--                  {{ errors.sources }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.quantity') }}</label>
                <input type="number" v-model="filter.quantity" :disabled="selectedRequests.length > 0">
                <span class="required-span" v-if="!filter.quantity && errors?.quantity">{{ errors.quantity }}</span>
              </div>
            </div>
            <div class="col-md-12 next-btn">
              <a href="javascript:void(0)" @click="getRequests()" class="btn-green">{{ $t('global.btn_compute') }}</a>
              <a @click="cleanFilter" href="javascript:void(0)" class="btn-white">{{ $t('global.btn_clean') }}</a>
            </div>
          </div>
        </form>
        <div v-if="items.length > 0 && !submitted && createdRequests.length === 0" class="row">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <div class="scrollable-table-container">
                <div class="table-wrapper">
                  <table class="bg-white d-none d-sm-table large-table" id="pedidosTable">
                    <thead>
                    <tr style="background-color: #fffadc;">
                      <th class="text-center">{{ $t('tdc.market') }}
                        <Tooltip :parent="'markets'" :message="$t('tdc.market')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.countries') }}
                        <Tooltip :parent="'countries'" :message="$t('tdc.countries')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.sources') }}
                        <Tooltip :parent="'sources'" :message="$t('tdc.sources')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.sku') }}
                        <Tooltip :parent="'skus'" :message="$t('tdc.sku')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.icp_kg') }}
                        <Tooltip :parent="'icp_kg'" :message="$t('tdc.icp_kg')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.export_charges') }}
                        <Tooltip :parent="'export_charges'" :message="$t('tdc.export_charges')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.weight') }}
                        <Tooltip :parent="'weight'" :message="$t('tdc.weight')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.carrier') }}
                        <Tooltip :parent="'carrier'" :message="$t('tdc.carrier')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.total_rate') }}
                        <Tooltip :parent="'total_rate'" :message="$t('tdc.total_rate')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.product_cost_freight') }}
                        <Tooltip :parent="'product_cost_freight'" :message="$t('tdc.product_cost_freight')">(*)
                        </Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.porcent_import_duties') }}
                        <Tooltip :parent="'porcent_import_duties'" :message="$t('tdc.porcent_import_duties')">(*)
                        </Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.duties') }}
                        <Tooltip :parent="'duties'" :message="$t('tdc.duties')">(*)</Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.in_land_in_country_rate') }}
                        <Tooltip :parent="'in_land_in_country_rate'" :message="$t('tdc.in_land_in_country_rate')">(*)
                        </Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.duties_in_land_in_country') }}
                        <Tooltip :parent="'duties_in_land_in_country'" :message="$t('tdc.duties_in_land_in_country')">
                          (*)
                        </Tooltip>
                      </th>
                      <th class="text-center">{{ $t('tdc.tdc_act') }}
                        <Tooltip :parent="'tdc_act'" :message="'TDC.'">(*)</Tooltip>
                      </th>
                    </tr>
                    </thead>
                    <tbody id="mostrarTablas">
                    <tr class="b-top registro-row" v-for="(r, i) in items" :key="i"
                        :class="{ 'selected-row': selectedRequests.includes(r) }" @click="onSelectRequest(r)">
                      <td class="text-center">{{ r.market }}</td>
                      <td class="text-center">{{ r.country }}</td>
                      <td class="text-center">{{ r.source }}</td>
                      <td class="text-center">{{ r.sku }}</td>
                      <td class="text-center">{{ this.formatNumber(r.icp_kg) }}</td>
                      <td class="text-center">{{ this.formatNumber(r.export_charges) }}</td>
                      <td class="text-center">{{ r.weight }}</td>
                      <td class="text-center">{{ r.carrier }}</td>
                      <td class="text-center">{{ this.formatNumber(r.total_rate) }}</td>
                      <td class="text-center">{{ this.formatNumber(r.product_cost_freight) }}</td>
                      <td class="text-center">{{ r.porcent_import_duties }}</td>
                      <td class="text-center">{{ this.formatNumber(r.duties) }}</td>
                      <td class="text-center">{{ r.in_land_in_country_rate }}</td>
                      <td class="text-center">{{ this.formatNumber(r.duties_in_land_in_country) }}</td>
                      <td class="text-center">{{ this.formatNumber(r.tdc_act) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top registro-row"
                     v-for="(r, i) in items" :key="i" @click="onSelectRequest(r)">
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.market') }}</th>
                  <td class="text-center">{{ r.market }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.countries') }}</th>
                  <td class="text-center">{{ r.country }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.sources') }}</th>
                  <td class="text-center">{{ r.source }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.sku') }}</th>
                  <td class="text-center">{{ r.sku }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.icp_kg') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.icp_kg) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.export_charges') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.export_charges) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.weight') }}</th>
                  <td class="text-center">{{ r.weight }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.carrier') }}</th>
                  <td class="text-center">{{ r.carrier }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.total_rate') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.total_rate) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.product_cost_freight') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.product_cost_freight) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.porcent_import_duties') }}</th>
                  <td class="text-center">{{ r.porcent_import_duties }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.duties') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.duties) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.in_land_in_country_rate') }}</th>
                  <td class="text-center">{{ r.in_land_in_country_rate }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.duties_in_land_in_country') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.duties_in_land_in_country) }}</td>
                </tr>
                <tr :class="{ 'selected-row': selectedRequests.includes(r) }">
                  <th class="text-center">{{ $t('tdc.tdc_act') }}</th>
                  <td class="text-center">{{ this.formatNumber(r.tdc_act) }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div v-if="loading && selectedRequests.length === 0" class="loading"></div>
        <div v-if="items.length > 0 && !submitted && createdRequests.length === 0" class="row">
          <div class="col-md-12 text-center mb-5">
            <Vue3BsPaginate
                v-model:modelValue="currentPage"
                :total="total"
                :perPage="perPage"
                :pagesShown="5"
                :firstJumpContent="firstJumpContent"
                :prevContent="prevContent"
                :nextContent="nextContent"
                :lastJumpContent="lastJumpContent"
                alignment="center"
                size="sm"
                showPrevNextButton
                showJumpButtons
            />
          </div>
        </div>
        <div v-if="selectedRequests.length > 0 && !submitted && createdRequests.length === 0" class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>{{ $t('tdc.comments') }}</label>
              <input type="text" class="comments" v-model="comments" :disabled="loading">
              <span class="required-span" v-if="!comments && errors?.comments">{{ errors.comments }}</span>
            </div>
          </div>
          <div class="col-md-12 loading" v-if="loading && selectedRequests.length > 0"></div>
          <div class="col-md-12 next-btn">
            <a href="javascript:void(0)" @click="onSelectedRequests" class="btn-green">{{ $t('global.btn_save') }}</a>
            <a href="javascript:void(0)" @click="toggleSelectedRequests" class="btn-white">{{
                $t('global.btn_cancel')
              }}</a>
          </div>
        </div>
        <div v-if="submitted && createdRequests.length > 0" class="row">
          <div class="col-md-12">
            <ul>
              <li v-for="(cr, i) in createdRequests" :key="i">{{ $t('tdc.request_number') }}: {{ cr.request_number }}
              </li>
            </ul>
          </div>
          <div class="col-md-12 next-btn">
            <a href="javascript:void(0)" @click="backToCreate" class="btn-green">{{ $t('tdc.requests.create') }}</a>
            <a :href="route + 'tdc'" class="btn-white">{{ $t('tdc.requests.title') }}</a>
          </div>
        </div>
      </div>
    </section>
    <vue-basic-alert :duration="700" :closeIn="7000" ref="alert"/>
  </div>
</template>

<script>
import {route} from "@/enviroment";
import axios from "axios";
import Tooltip from "../common/Tooltip.vue"
import {Vue3BsPaginate} from "vue3-bootstrap-paginate";

export default {
  name: 'tdcRequestForm',
  components: {
    Tooltip,
    Vue3BsPaginate
  },
  data: function () {
    return {
      route: route,
      markets: {
        options: [],
        loading: false
      },
      divisions: {
        options: [],
        loading: false
      },
      categories: {
        options: [],
        loading: false
      },
      subcategories: {
        options: [],
        loading: false
      },
      families: {
        options: [],
        loading: false
      },
      formTypes: {
        options: [],
        loading: false
      },
      skus: {
        values: [],
        options: [],
        loading: false
      },
      countries: {
        options: [],
        loading: false
      },
      sources: {
        options: [],
        loading: false
      },
      filter: {
        market: null,
        divisions: [],
        categories: [],
        subcategories: [],
        families: [],
        formTypes: [],
        skus: [],
        countries: [],
        sources: [],
        quantity: '',
      },
      requests: [],
      perPage: 10,
      currentPage: 1,
      lastPage: 1,
      errors: [],
      selectedRequests: [],
      comments: '',
      createdRequests: [],
      submitted: false,
      loading: false
    }
  },
  created() {
    this.getMarketOptions();
  },
  computed: {
    firstJumpContent() {
      return this.$t('global.pagination.first');
    },
    prevContent() {
      return this.$t('global.pagination.prev');
    },
    nextContent() {
      return this.$t('global.pagination.next');
    },
    lastJumpContent() {
      return this.$t('global.pagination.last');
    },
    total() {
      return this.requests.length
    },
    items() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.requests.slice(startIndex, endIndex);
    },
  },
  watch: {
    'filter.market': function (element) {
      this.divisions.options = [];
      this.categories.options = [];
      this.subcategories.options = [];
      this.families.options = [];
      this.formTypes.options = [];
      this.skus.options = [];
      this.countries.options = [];
      this.sources.options = [];
      this.filter.divisions = [];
      this.filter.categories = [];
      this.filter.subcategories = [];
      this.filter.families = [];
      this.filter.formTypes = [];
      this.filter.skus = [];
      this.filter.countries = [];
      this.filter.sources = [];
      if (element) this.getSkuOptions();
    },
    'filter.divisions': function (selectedDivisions) {
      this.filterSkus(selectedDivisions, 'divisions');
    },
    'filter.categories': function (selectedCategories) {
      this.filterSkus(selectedCategories, 'categories');
    },
    'filter.subcategories': function (selectedSubCategories) {
      this.filterSkus(selectedSubCategories, 'subcategories');
    },
    'filter.families': function (selectedFamilies) {
      this.filterSkus(selectedFamilies, 'families');
    },
    'filter.formTypes': function (selectedFormTypes) {
      this.filterSkus(selectedFormTypes, 'formTypes');
    },
    'filter.skus': function (selectedSkus) {
      this.countries.options = [];
      this.sources.options = [];
      this.filter.countries = [];
      this.filter.sources = [];
      const uniqueCountries = new Set();
      selectedSkus.forEach(sku => {
        if (sku.country) uniqueCountries.add(sku.country);
      });
      this.countries.options = Array.from(uniqueCountries);
    },
    'filter.countries': function (selectedCountries) {
      this.sources.options = [];
      this.filter.sources = [];
      const uniqueSources = new Set();
      this.filter.skus.forEach(sku => {
        if (selectedCountries.includes(sku.country)) uniqueSources.add(sku.source);
      });
      this.sources.options = Array.from(uniqueSources);
    }
  },
  methods: {
    showAlert(type, title, message) {
      if (this.$refs.alert) {
        this.$refs.alert.showAlert(
            type, // There are 4 types of alert: success, info, warning, error
            message, // Message of the alert
            title, // Header of the alert
            {
              iconSize: 35, // Size of the icon (px)
              iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
              position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            }
        )
      }
    },
    formatNumber(value, position = 4) {
      const strValue = value.toString();
      const decimalPosition = strValue.indexOf('.');
      return decimalPosition === -1 || decimalPosition + position >= strValue.length
          ? strValue
          : strValue.substring(0, decimalPosition + position);
    },
    getMarketOptions() {
      this.markets.loading = true;
      axios
          .get('/tdc/markets')
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.markets.options = data.markets;
            this.markets.loading = false;
          })
          .catch((error) => {
            this.markets.options = [];
            this.markets.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getCountryOptions(market) {
      this.countries.loading = true;
      const query = new URLSearchParams({market}).toString();
      axios
          .get(`/tdc/countries?${query}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.countries.options = data.countries;
            this.countries.loading = false;
          })
          .catch((error) => {
            this.countries.options = [];
            this.countries.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getSourceOptions(market, countries) {
      this.sources.options = [];
      this.sources.loading = true;
      const query = new URLSearchParams({market, countries}).toString();
      axios
          .get(`/tdc/sources?${query}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.sources.options = data.sources;
            this.sources.loading = false;
          })
          .catch((error) => {
            this.sources.options = [];
            this.sources.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getFilterData() {
      const filterObject = JSON.parse(JSON.stringify(this.filter));

      filterObject.market = filterObject.market.market;
      filterObject.countries = filterObject.countries.map(item => item.country);
      filterObject.sources = filterObject.sources.map(item => item.source);
      delete filterObject.skus;
      delete filterObject.quantity;

      return filterObject;
    },
    getSkusFilterQuery() {
      const query = new URLSearchParams(this.getFilterData()).toString();
      return (query) ? `&${query}` : ``;
    },
    getSkuOptions() {
      this.skus.options = [];
      this.skus.loading = true;
      axios
          .get(`/tdc/skus?${this.getSkusFilterQuery()}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));

            const uniqueDivisions = new Set();
            const uniqueCategories = new Set();
            const uniqueSubCategories = new Set();
            const uniqueFamilies = new Set();
            const uniqueFormTypes = new Set();
            const uniqueCountries = new Set();
            const uniqueSources = new Set();

            data.skus.forEach(sku => {
              if (sku.division) uniqueDivisions.add(sku.division);
              if (sku.category) uniqueCategories.add(sku.category);
              if (sku.subcategory) uniqueSubCategories.add(sku.subcategory);
              if (sku.family) uniqueFamilies.add(sku.family);
              if (sku.form_type) uniqueFormTypes.add(sku.form_type);
              if (sku.country) uniqueCountries.add(sku.country);
              if (sku.source) uniqueSources.add(sku.source);
            });

            this.divisions.options = Array.from(uniqueDivisions);
            this.categories.options = Array.from(uniqueCategories);
            this.subcategories.options = Array.from(uniqueSubCategories);
            this.families.options = Array.from(uniqueFamilies);
            this.formTypes.options = Array.from(uniqueFormTypes);
            this.countries.options = Array.from(uniqueCountries);
            this.sources.options = Array.from(uniqueSources);
            this.skus.values = data.skus;
            this.skus.options = data.skus;
            this.skus.loading = false;
          })
          .catch((error) => {
            this.skus.options = [];
            this.skus.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    filterSkus(selectedFilter, filterType) {
      const filterMapping = {
        divisions: 'division',
        categories: 'category',
        subcategories: 'subcategory',
        families: 'family',
        formTypes: 'form_type',
      };

      const isAnyFilterActive = Object.keys(filterMapping).some(
          type => this.filter[type] && this.filter[type].length > 0
      );

      if (!isAnyFilterActive) {
        this.skus.options = [...this.skus.values];
      } else {
        const itemProperty = filterMapping[filterType];

        this.skus.options = this.skus.values.filter(
            item => itemProperty &&
                Object.keys(filterMapping).every(
                    type => !this.filter[type]?.length ||
                        this.filter[type].some(selectedValue => selectedValue === item[filterMapping[type]])
            )
        );
      }

      const uniqueDivisions = new Set();
      const uniqueCategories = new Set();
      const uniqueSubCategories = new Set();
      const uniqueFamilies = new Set();
      const uniqueFormTypes = new Set();

      this.skus.options.forEach(sku => {
        if (sku.division) uniqueDivisions.add(sku.division);
        if (sku.category) uniqueCategories.add(sku.category);
        if (sku.subcategory) uniqueSubCategories.add(sku.subcategory);
        if (sku.family) uniqueFamilies.add(sku.family);
        if (sku.form_type) uniqueFormTypes.add(sku.form_type);
      });

      this.divisions.options = Array.from(uniqueDivisions);
      this.categories.options = Array.from(uniqueCategories);
      this.subcategories.options = Array.from(uniqueSubCategories);
      this.families.options = Array.from(uniqueFamilies);
      this.formTypes.options = Array.from(uniqueFormTypes);
    },
    formatSkuLabel(sku, extended = true) {
      let ai = '';

      if (extended && this.filter.countries.length > 1) {
        ai += `${this.$t('tdc.countries')}: ${sku.country}`;
      }

      if (extended && this.filter.sources.length > 1) {
        ai += ai.length > 0
            ? `, ${this.$t('tdc.sources')}: ${sku.source}`
            : `${this.$t('tdc.sources')}: ${sku.source}`;
      }

      // Add parentheses only if there is any appended info
      ai = ai.length > 0 ? `(${ai})` : '';

      return `${sku.sku_id} - ${sku.sku_name} ${ai}`;
    },
    cleanFilter() {
      if (this.loading || this.selectedRequests.length > 0) return;
      this.errors = [];
      this.skus.options = [];
      this.currentPage = 1;
      this.requests = [];
      this.selectedRequests = [];
      this.createdRequests = [];
      this.comments = '';
      this.submitted = false;
      this.filter = {
        market: null,
        countries: [],
        sources: [],
        skus: [],
        quantity: '',
      };
    },
    async getRequests() {
      if (this.loading || this.selectedRequests.length > 0) return;

      this.comments = '';
      this.errors = {};
      this.currentPage = 1;

      const markets = this.filter.market ? [this.filter.market.market] : [];
      if (markets.length === 0) this.errors['markets'] = `${this.$t('tdc.form.validation.select_single_choice')} ${this.$t('tdc.market')}.`;

      // const countries = this.filter.countries.map(item => item.country);
      // if (countries.length === 0) this.errors['countries'] = `${this.$t('tdc.form.validation.select_multiple_choice')} ${this.$t('tdc.countries')}.`;
      //
      // const sources = this.filter.sources.map(item => item.source);
      // if (sources.length === 0) this.errors['sources'] = `${this.$t('tdc.form.validation.select_multiple_choice')} ${this.$t('tdc.sources')}.`;
      //
      // if (this.filter.skus.length === 0) this.errors['skus'] = `${this.$t('tdc.form.validation.select_multiple_choice')} ${this.$t('tdc.sku')}.`;

      if (!this.filter.quantity) this.errors['quantity'] = `${this.$t('tdc.form.validation.required_quantity')} ${this.$t('tdc.quantity')}.`;

      if (Object.keys(this.errors).length > 0) return;

      this.loading = true;
      try {
        await this.setRequests();
      } finally {
        this.loading = false;
      }
    },
    setRequests() {
      // this.requests = this.filter.skus.map(item => {
      this.requests = this.skus.options.map(item => {
        const weight = item.weight > 0 ? item.weight : 1;
        const icpKg = item.price_kg * 1; // los cálculos deberían ser en base a 1kg
        const productCost = (item.freight / weight) + item.export_charges + icpKg;
        const duties = productCost * item.porcent_import_duties;
        const dutiesLand = (item.in_land_in_country_rate / weight) + duties;
        const tdcAct = productCost + dutiesLand;
        return {
          market: item.market,
          country: item.country,
          source: item.source,
          sku_sap: item.sku_id,
          sku_name: item.sku_name,
          sku: this.formatSkuLabel(item, false),
          quantity: this.filter.quantity,
          icp_kg: icpKg,
          export_charges: item.export_charges,
          weight: item.weight,
          carrier: item.carrier,
          total_rate: item.freight,
          product_cost_freight: productCost,
          porcent_import_duties: item.porcent_import_duties,
          duties: duties,
          in_land_in_country_rate: item.in_land_in_country_rate,
          duties_in_land_in_country: dutiesLand,
          tdc_act: tdcAct
        };
      }).sort((a, b) => a.tdc_act - b.tdc_act);
    },
    onSelectRequest(request) {
      if (this.loading) return;
      const index = this.selectedRequests.findIndex(r => (
          r.market.toString() === request.market.toString() &&
          r.country.toString() === request.country.toString() &&
          r.source.toString() === request.source.toString() &&
          r.sku_sap.toString() === request.sku_sap.toString() &&
          r.sku_name.toString() === request.sku_name.toString() &&
          r.quantity.toString() === request.quantity.toString() &&
          r.icp_kg.toString() === request.icp_kg.toString() &&
          r.export_charges.toString() === request.export_charges.toString() &&
          r.weight.toString() === request.weight.toString() &&
          r.carrier.toString() === request.carrier.toString() &&
          r.total_rate.toString() === request.total_rate.toString() &&
          r.product_cost_freight.toString() === request.product_cost_freight.toString() &&
          r.porcent_import_duties.toString() === request.porcent_import_duties.toString() &&
          r.duties.toString() === request.duties.toString() &&
          r.in_land_in_country_rate.toString() === request.in_land_in_country_rate.toString() &&
          r.duties_in_land_in_country.toString() === request.duties_in_land_in_country.toString() &&
          r.tdc_act.toString() === request.tdc_act.toString()
      ));
      if (index === -1) {
        this.selectedRequests.push(request);
      } else {
        this.selectedRequests.splice(index, 1);
      }
    },
    toggleSelectedRequests() {
      if (this.loading) return;
      this.errors = [];
      this.currentPage = 1;
      this.selectedRequests = [];
      this.comments = '';
    },
    onSelectedRequests() {
      if (this.loading) return;

      this.errors = [];
      this.currentPage = 1;
      this.createdRequests = [];
      this.submitted = false;

      if (this.selectedRequests.length === 0) this.errors['selectedRequests'] = `${this.$t('tdc.form.validation.select_multiple_choice')}`;

      if (!this.comments) this.errors['comments'] = `${this.$t('tdc.form.validation.required_comments')}`;

      if (Object.keys(this.errors).length > 0) return;

      this.loading = true;
      axios
          .post(
              '/tdc/requests',
              {
                "requests": this.selectedRequests,
                "comments": this.comments,
              }
          )
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.createdRequests = data.createdRequests;
            this.sendApprovalNotifications();
            this.submitted = true;
            this.loading = false;
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          })
          .catch((error) => {
            this.requests = [];
            this.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    sendApprovalNotifications() {
      if (this.createdRequests.length === 0) return;
      axios
          .post(
              '/tdc/approval/notifications',
              {
                "requests": this.createdRequests
              }
          )
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.showAlert('info', this.$t('global.alert_info_title'), this.$t('tdc.notified'));
          })
          .catch((error) => {
            this.requests = [];
            this.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    backToCreate() {
      if (this.loading) return;
      this.errors = [];
      this.currentPage = 1;
      this.selectedRequests = [];
      this.comments = '';
      this.createdRequests.forEach(cr => {
        const index = this.requests.findIndex(r => this.isMatchingRequest(r, cr));
        if (index >= 0) {
          this.requests.splice(index, 1);
        }
      });
      this.createdRequests = [];
      this.submitted = false;
      if (this.requests.length === 0) {
        this.filter = {
          markets: null,
          countries: [],
          sources: [],
          skus: [],
          quantity: '',
        };
      }
    },
    isMatchingRequest(r, cr) {
      return (
          r.market.toString() === cr.market.toString() &&
          r.country.toString() === cr.country.toString() &&
          r.source.toString() === cr.source.toString() &&
          r.sku_sap.toString() === cr.sku_sap.toString() &&
          r.sku_name.toString() === cr.sku_name.toString() &&
          r.quantity.toString() === cr.quantity.toString() &&
          this.formatNumber(r.icp_kg) === this.formatNumber(cr.icp_kg) &&
          this.formatNumber(r.export_charges) === this.formatNumber(cr.export_charges) &&
          r.weight.toString() === cr.weight.toString() &&
          r.carrier.toString() === cr.carrier.toString() &&
          this.formatNumber(r.total_rate) === this.formatNumber(cr.total_rate) &&
          this.formatNumber(r.product_cost_freight) === this.formatNumber(cr.product_cost_freight) &&
          r.porcent_import_duties.toString() === cr.porcent_import_duties.toString() &&
          this.formatNumber(r.duties) === this.formatNumber(cr.duties) &&
          r.in_land_in_country_rate.toString() === cr.in_land_in_country_rate.toString() &&
          this.formatNumber(r.duties_in_land_in_country) === this.formatNumber(cr.duties_in_land_in_country) &&
          this.formatNumber(r.tdc_act) === this.formatNumber(cr.tdc_act)
      );
    },
    // handleRemove(option) {
    //   this.filter.skus = this.filter.skus.filter(
    //     item => item.sku_id !== option.sku_id
    //   );
    // }
  }
}
</script>

<style scoped>
.scrollable-table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.table-wrapper {
  width: 1000px; /* Set a fixed width to enable scrolling */
}

.large-table {
  width: 100%;
  border-collapse: collapse;
}

.large-table th,
.large-table td {
  border: 1px solid #ccc;
}

.pedido-listado .confirmado table th {
  padding: 28px;
}

.pedido-listado .confirmado table td {
  padding: 28px;
}

.selected-row {
  background-color: lightgray;
}

.comments {
  height: 100%;
  min-height: 10vh;
}

</style>