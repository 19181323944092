<template>
   <footer class="copyright bg-black">
		<div class="text-center">
			<p class="text-center">Copyright © 2015. McCain. {{ $t('footer.copyright') }}</p>
		</div>
	</footer>
</template>
<script>
export default {
	name: "FooterCopyright",
	props: {

	},
	data() {
		return {
		}
	},
}
</script>