<template>
  <div class="pedido-listado">
    <section class="pedido-steps">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block d-sm-none">
              <router-link :to="route">
                <img :src="require('@/assets/img/backspace.svg')" class="mb-5">
              </router-link>
            </div>
            <div class="section-title">
              <h5 class="pull-left">Consulta de Registros Sanitarios</h5>
              <router-link :to="route">
                <img class="d-none d-sm-block" :src="require('@/assets/img/backspace.svg')">
              </router-link>
            </div>
          </div>
        </div>
        <form class="form-filter">
          <div class="row">
            <!-- <div class="col-md-1">
                <div class="form-group">
                    <label># Registro</label>
                    <input type="text" id="number-rs" name="number-rs" v-model="filter.number">
                </div>
            </div> -->
            <div class="col-md-4">
              <div class="form-group">
                <label>Distribuidor</label>
                <VueMultiselect
                    v-model="filter.distributor"
                    :options="this.filterData.distributors"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :custom-label="({ code_distributor, name_distributor }) => `${code_distributor} - ${name_distributor}`"
                    placeholder="Seleccione un Distribuidor"
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>SKU</label>
                <VueMultiselect
                    v-model="filter.sku"
                    :options="this.filterData.skus"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :custom-label="({ code_sku, name_sku }) => `${code_sku} - ${name_sku}`"
                    placeholder="Seleccione un SKU"
                />
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Estado</label>
                <select name="status" v-model="filter.status_id">
                  <option v-for="(status,index) in this.filterData.statuses" :key="index" :value="status.id">
                    {{ status.status }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Sub Estado</label>
                <select name="sub_status" v-model="filter.sub_status_id">
                  <option v-for="(sub_statuses,index) in this.filterData.sub_statuses" :key="index"
                          :value="sub_statuses.id">{{ sub_statuses.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-1">
              <div class="form-group">
                <label>Source</label>
                <select name="source" v-model="filter.source_id">
                  <option v-for="(source,index) in this.filterData.sources" :key="index" :value="source.id">
                    {{ source.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>País de Venta</label>
                <select name="commercial_country" v-model="filter.commercial_country_id">
                  <option v-for="(commercial_country,index) in this.filterData.commercial_countries" :key="index"
                          :value="commercial_country.id">{{ commercial_country.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-3" v-if="this.rol=='Admin'">
              <div class="form-group">
                <label>Fecha de Finalizacion</label>
                <Datepicker
                    v-model="filter.finish_date"
                    name="fecha-finish"
                    :enableTimePicker="false"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    utc="preserve"
                    required
                    range
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Fecha de Vencimiento</label>
                <Datepicker
                    v-model="filter.expiration_date"
                    name="fecha-vencimiento"
                    :enableTimePicker="false"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    utc="preserve"
                    required
                    range
                />
              </div>
            </div>
            <div class="col-md-3" v-if="this.rol=='Admin'">
              <div class="form-group">
                <label>Fecha de Actualizacion</label>
                <Datepicker
                    v-model="filter.actualization_date"
                    name="fecha-vencimiento"
                    :enableTimePicker="false"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    utc="preserve"
                    required
                    range
                />
              </div>
            </div>
            <div class="col-md-3" v-if="this.rol=='Admin'">
              <div class="form-group">
                <label>Fecha de Cambio</label>
                <Datepicker
                    v-model="filter.change_date"
                    name="fecha-change"
                    :enableTimePicker="false"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    utc="preserve"
                    required
                    range
                />
              </div>
            </div>
            <div class="col-md-2" v-if="this.rol=='User'">
              <div class="form-group">
                <label>Estado Comercial</label>
                <select name="status_commercial" v-model="filter.status_commercial_id">
                  <option v-for="(status_commercial,index) in this.filterData.statuses_commercial" :key="index"
                          :value="status_commercial.id">{{ status_commercial.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label>Canal</label>
                <select name="channel_id" v-model="filter.channel_id">
                  <option v-for="(channel,index) in this.filterData.channels" :key="index" :value="channel.id">
                    {{ channel.channel }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 next-btn">
              <a href="javascript:void(0)" @click="getRegistros()" class="btn-green">Buscar</a>
              <a @click="cleanFilter" href="javascript:void(0)" class="btn-white">Limpiar</a>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                <tr style="background-color: #fffadc;">
                  <th class="text-center">SKU
                    <Tooltip :parent="'skus'" :message="'Numero de identificación interno Mccain.'">(*)</Tooltip>
                  </th>
                  <th class="text-center">Canal
                    <Tooltip :parent="'channel'" :message="'Canal de Venta en Mccain.'">(*)</Tooltip>
                  </th>
                  <th class="text-center">Distribuidores
                    <Tooltip :parent="'distribuidores'" :message="'Distribuidor Autorizado en el registro sanitario.'">
                      (*)
                    </Tooltip>
                  </th>
                  <th class="text-center">Source
                    <Tooltip :parent="'source'" :message="'País de Producción del Producto.'">(*)</Tooltip>
                  </th>
                  <th class="text-center">País de Venta
                    <Tooltip :parent="'sales_countries'" :message="'Paises con Registros Sanitarios para la Venta.'">
                      (*)
                    </Tooltip>
                  </th>
                  <th class="text-center">Numero Registro
                    <Tooltip :parent="'numero'" :message="'Numero de Registro Sanitario'">(*)</Tooltip>
                  </th>
                  <th class="text-center">F. Vencimiento
                    <Tooltip :parent="'expiration_date'" :message="'Fecha de Vencimiento del Registro Sanitario'">(*)
                    </Tooltip>
                  </th>
                  <th v-if="this.rol=='Admin'" class="text-center">F. Finalizacion
                    <Tooltip :parent="'finish_date'" :message="'Fecha de culminación de Innovación'">(*)</Tooltip>
                  </th>
                  <th v-if="this.rol=='Admin'" class="text-center">F. Actualizacion
                    <Tooltip :parent="'actualization_date'" :message="'Fecha de Update del Registro.'">(*)</Tooltip>
                  </th>
                  <th v-if="this.rol=='Admin'" class="text-center">F. Cambio
                    <Tooltip :parent="'change_date'" :message="'Fecha de Adición de Distribuidor.'">(*)</Tooltip>
                  </th>
                  <th class="text-center">Estado
                    <Tooltip :parent="'status'" :message="'Estado del Registro.'">(*)</Tooltip>
                  </th>
                  <th class="text-center">Sub Estado
                    <Tooltip :parent="'sub_status'" :message="'Estatus de Procesos  en el registro'">(*)</Tooltip>
                  </th>
                  <th v-if="this.rol=='User'" class="text-center">Estado Comercial
                    <Tooltip :parent="'commercial_status'" :message="'Estatus del Registro para Ventas'">(*)</Tooltip>
                  </th>
                </tr>
                </thead>
                <tbody id="mostrarTablas">
                <tr class="b-top registro-row" v-for="(registro,index) in this.registros" :key="index"
                    @click="verRegistro(registro.id)">
                  <td class="text-center" style="white-space: break-spaces;">
                    {{ registro.skus?.map(s => `${s.code_sku}`).join(`, `) }}
                  </td>
                  <td class="text-center">{{ registro.channel?.channel }}</td>
                  <td class="text-center" style="white-space: break-spaces;">
                    {{ registro.distributors?.map(d => `${d.name_distributor}`).join(`, `) }}
                  </td>
                  <td class="text-center">{{ registro.sources?.map(s => `${s?.source?.name}`).join(`, `) }}</td>
                  <td class="text-center">
                    {{ registro.commercial_countries?.map(c => `${c?.commercial_country?.name}`).join(`, `) }}
                  </td>
                  <td class="text-center">{{ registro.number }}</td>
                  <td class="text-center">{{ registro.expiration_date }}</td>
                  <td v-if="this.rol=='Admin'" class="text-center">{{ registro.finish_date }}</td>
                  <td v-if="this.rol=='Admin'" class="text-center">{{ registro.actualization_date }}</td>
                  <td v-if="this.rol=='Admin'" class="text-center">{{ registro.change_date }}</td>
                  <td class="text-center">{{ registro.status?.status }}</td>
                  <td class="text-center">{{ registro.sub_status?.name }}</td>
                  <td v-if="this.rol=='User'" class="text-center">{{ registro.status_commercial?.name }}</td>
                </tr>
                </tbody>
              </table>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top registro-row"
                     v-for="(registro,index) in this.registros" :key="index" @click="verRegistro(registro.id)">
                <tr>
                  <th>ID</th>
                  <td>{{ registro.id }}</td>
                </tr>
                <tr>
                  <th class="text-center">SKU</th>
                  <td class="text-center">{{ registro.skus?.map(s => `${s.code_sku} - ${s.name_sku}`).join(`\r\n`) }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center">Canal</th>
                  <td class="text-center">{{ registro.channel?.channel }}</td>
                </tr>
                <tr>
                  <th class="text-center">Distribuidores</th>
                  <td class="text-center">
                    {{ registro.distributors?.map(d => `${d.code_distributor} - ${d.name_distributor}`).join(`\r\n`) }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center">Source</th>
                  <td class="text-center">{{ registro.sources?.map(c => `${c.source?.name}`).join(`\r\n`) }}</td>
                </tr>
                <tr>
                  <th class="text-center">País de Venta</th>
                  <td class="text-center">
                    {{ registro.commercial_countries?.map(c => `${c.commercial_country?.comercial_country}`).join(`\r\n`) }}
                  </td>
                </tr>
                <tr>
                  <th class="text-center">Registro</th>
                  <td class="text-center">{{ registro.number }}</td>
                </tr>
                <tr>
                  <th class="text-center">Fecha de Vencimiento</th>
                  <td class="text-center">{{ registro.expiration_date }}</td>
                </tr>
                <tr v-if="this.rol=='Admin'">
                  <th class="text-center">F. Finalizacion</th>
                  <td class="text-center">{{ registro.finish_date }}</td>
                </tr>
                <tr v-if="this.rol=='Admin'">
                  <th class="text-center">F. Actualizacion</th>
                  <td class="text-center">{{ registro.actualization_date }}</td>
                </tr>
                <tr v-if="this.rol=='Admin'">
                  <th class="text-center">Fecha de Cambio</th>
                  <td class="text-center">{{ registro.change_date }}</td>
                </tr>
                <tr>
                  <th class="text-center">Estado</th>
                  <td class="text-center">{{ registro.status?.status }}</td>
                </tr>
                <tr>
                  <th class="text-center">Sub Estado</th>
                  <td class="text-center">{{ registro.sub_status?.name }}</td>
                </tr>
                <tr v-if="this.rol=='User'">
                  <th class="text-center">Estado Comercial</th>
                  <td class="text-center">{{ registro.status_commercial?.name }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="loading"></div>
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <Bootstrap4Pagination :data="laravelData" :limit="5" @pagination-change-page="getRegistros"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 next-btn">
            <a href="javascript:void(0)" @click="newRS" class="btn-green" v-if="this.rol=='Admin'">Nuevo</a>
            <a href="javascript:void(0)" @click="exportCSV" class="btn-green" v-if="this.rol=='Admin'">Descargar</a>
          </div>
        </div>
      </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
  </div>
</template>

<script>
import {route} from "@/enviroment";
import axios from 'axios';
import $ from 'jquery';
import {Bootstrap4Pagination} from 'laravel-vue-pagination';
import Tooltip from "../common/Tooltip.vue"
import {clearSessionData} from "@/router";

export default {
  name: 'RegistroSanitarioListado',
  components: {
    Bootstrap4Pagination, Tooltip
  },
  data: function () {
    return {
      registros: [],
      route: route,
      rol: 'User',
      laravelData: {},
      filterData: {
        skus: [],
        distributors: [],
        channels: [],
        statuses: [],
        statuses_commercial: [],
        commercial_countries: []
      },
      filter: {}
    }
  },
  async created() {
    await this.getUser();
    this.getStoredFilter();
    this.getRegistros();
    this.getData();
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    verRegistro(id) {
      location.href = route + 'registro-sanitario-detalle/' + id;
    },
    mostrarCarga() {
      $('.loading').removeClass('d-none');
      $('#mostrarTablas').addClass('d-none');
    },
    ocultarCarga() {
      $('.loading').addClass('d-none');
      $('#mostrarTablas').removeClass('d-none');
    },
    getRegistros(page = 1) {
      this.mostrarCarga();
      axios.get(`/registro-sanitario?page=${page}${this.getFilterQuery()}`)
          .then((response) => {
            this.ocultarCarga()
            this.laravelData = response.data.data;
            this.registros = response.data.data.data;
          })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = "/login";
            } else {
              this.showAlert('warning', 'Atencion!', error.response.data.error.message);
            }
          });
    },
    newRS() {
      location.href = route + 'registro-sanitario-nuevo';
    },
    getUser() {
      axios.get('/auth/user'
      ).then((response) => {
        this.user = response.data.data.profile.name;
        const adminRol = response.data.data.profile.Admin_RS ?? null;
        const userRol = response.data.data.profile.User_RS ?? null;
        if (adminRol || userRol) {
          this.rol = adminRol ? 'Admin' : 'User';
        } else {
          clearSessionData();
          location.href = route + "login";
        }
      })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    async getData() {
      axios.get('/data')
          .then((response) => {
            if (response.data.data != "") {
              this.filterData = response.data.data;
            }
            this.ocultarCarga()
          })
          .catch((error) => {
            this.showAlert('warning', 'Atencion!', error.response.data.error.message);
          });
    },
    cleanFilter() {
      this.filter = {};
      this.setStoredFilter();
      this.getRegistros();
    },
    getFilterQuery() {
      const filterObject = JSON.parse(JSON.stringify(this.filter));

      if (filterObject.expiration_date !== undefined) {
        filterObject.expiration_date = this.filter.expiration_date;
        filterObject.expiration_date = filterObject.expiration_date.map(e => e.split('T')[0]);
      }

      if (filterObject.finish_date !== undefined) {
        filterObject.finish_date = this.filter.finish_date;
        filterObject.finish_date = filterObject.finish_date.map(e => e.split('T')[0]);
      }

      if (filterObject.change_date !== undefined) {
        filterObject.change_date = this.filter.change_date;
        filterObject.change_date = filterObject.change_date.map(e => e.split('T')[0]);
      }

      if (filterObject.actualization_date !== undefined) {
        filterObject.actualization_date = this.filter.actualization_date;
        filterObject.actualization_date = filterObject.actualization_date.map(e => e.split('T')[0]);
      }

      if (filterObject.sku !== undefined) {
        filterObject.code_sku = this.filter.sku.code_sku;
      }

      if (filterObject.distributor !== undefined) {
        filterObject.code_distributor = this.filter.distributor.code_distributor;
      }


      delete filterObject.sku;
      delete filterObject.distributor;
      const query = new URLSearchParams(filterObject).toString();
      this.setStoredFilter();
      return (query) ? `&${query}` : ``;
    },
    exportCSV() {
      let url = (process.env.NODE_ENV == 'production') ? '../api/' : process.env.VUE_APP_BACKEND_API_URL;
      url += `export?${this.getFilterQuery()}`;
      window.open(url, "_self");
    },
    getStoredFilter() {
      if (localStorage.filter) {
        this.filter = JSON.parse(localStorage.filter);
      } else {
        this.filter = {};
      }
    },
    setStoredFilter() {
      localStorage.filter = JSON.stringify(this.filter);
    }
  }
}
</script>

<style>

.registro-row {
  cursor: pointer;
}

.registro-row:hover {
  background-color: #e8e8e8;
}
</style>