/* eslint-disable */
<template>
  <section class="pedido-steps">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h4>Detalle</h4>
            <a @click="volverListado()" href="javascript:void(0)"><img class="d-none d-sm-block"
                                                                       :src="require('@/assets/img/backspace.svg')"></a>
          </div>
        </div>
      </div>
      <div class="row mostrarPedido">
        <div class="col-md-12">
          <div class="confirmado">
            <div class="section-title p-0">
              <h5>Registro ID
                <Tooltip :parent="'id'" :message="'Numero de Item en el sistema de Registro.'">(*)</Tooltip>
                : {{ data.id }}
              </h5>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group" id="days-to-expiration">
                  <label><strong>Numero Registro
                    <Tooltip :parent="'numero'" :message="'Numero de Registro Sanitario.'">(*)</Tooltip>
                    : </strong>{{ data.number }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="lead-time">
                  <label><strong>Canal
                    <Tooltip :parent="'channel'" :message="'Canal de Venta en Mccain.'">(*)</Tooltip>
                    : </strong>{{ data.channel?.channel }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group" id="days-to-expiration">
                  <label><strong>Distribuidores
                    <Tooltip :parent="'distribuidores'" :message="'Distribuidor Autorizado en el registro sanitario.'">
                      (*)
                    </Tooltip>
                    :</strong></label>
                  <ul>
                    <li :key="distributor" v-for="distributor in data.distributors">{{ distributor.code_distributor }} -
                      {{ distributor.name_distributor }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="lead-time">
                  <label><strong>Skus
                    <Tooltip :parent="'skus'" :message="'Numero de identificación interno Mccain.'">(*)</Tooltip>
                    :</strong></label>
                  <ul>
                    <li :key="sku" v-for="sku in data.skus">{{ sku.code_sku }} - {{ sku.name_sku }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-3">
                <div class="form-group" id="source">
                  <label><strong>Source
                    <Tooltip :parent="'source'" :message="'País de Producción del Producto.'">(*)</Tooltip>
                    :</strong></label>
                  <ul>
                    <li :key="source" v-for="source in data.sources">{{ source.source.name }}</li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" id="country">
                  <label><strong>País de Venta
                    <Tooltip :parent="'sales_countries'" :message="'Paises con Registros Sanitarios para la Venta.'">
                      (*)
                    </Tooltip>
                    :</strong></label>
                  <ul>
                    <li :key="commercial_country" v-for="commercial_country in data.commercial_countries">
                      {{ commercial_country.commercial_country.name }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" id="status">
                  <label><strong>Estado
                    <Tooltip :parent="'status'" :message="'Estado del Registro.'">(*)</Tooltip>
                    : </strong>{{ data.status?.status }}</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group" id="sub_status">
                  <label><strong>Sub Estado
                    <Tooltip :parent="'sub_status'" :message="'Estatus de Procesos  en el registro.'">(*)</Tooltip>
                    : </strong>{{ data.sub_status?.name }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto" v-if="rol=='Admin'">
              <div class="col-md-6">
                <div class="form-group" id="days-to-expiration">
                  <label><strong>Dias hasta vencimiento
                    <Tooltip :parent="'days_to_expiration'"
                             :message="'Este campo hace referencia a los dias que faltan hasta el vencimiento del registro sanitario.'">
                      (*)
                    </Tooltip>
                    : </strong>{{ data.days_to_expiration }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="lead-time">
                  <label><strong>Lead Time
                    <Tooltip :parent="'lead_time'"
                             :message="'Este campo hace referencia al lead time del registro sanitario.'">(*)
                    </Tooltip>
                    : </strong>{{ data.lead_time }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group" id="expiration-date">
                  <label><strong>Fecha de Vencimiento
                    <Tooltip :parent="'expiration_date'" :message="'Fecha de Vencimiento del Registro Sanitario.'">(*)
                    </Tooltip>
                    : </strong>{{ data.expiration_date }}</label>
                </div>
              </div>
              <div class="col-md-6" v-if="rol=='Admin'">
                <div class="form-group" id="change-date">
                  <label><strong>Fecha de Cambio
                    <Tooltip :parent="'change_date'" :message="'Fecha de Adición de Distribuidor.'">(*)</Tooltip>
                    : </strong>{{ data.change_date }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto" v-if="rol=='Admin'">
              <div class="col-md-6">
                <div class="form-group" id="finish-date">
                  <label><strong>Fecha de Finalizacion
                    <Tooltip :parent="'finish_date'" :message="'Fecha de culminación de Innovación.'">(*)</Tooltip>
                    : </strong>{{ data.finish_date }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="actualization-date">
                  <label><strong>Fecha de Actualizacion
                    <Tooltip :parent="'actualization_date'" :message="'Fecha de Update del Registro.'">(*)</Tooltip>
                    : </strong>{{ data.actualization_date }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group" id="comments">
                  <label><strong>Comentarios
                    <Tooltip :parent="'comentarios'" :message="'Notas adicionales del  Registro.'">(*)</Tooltip>
                    : </strong>{{ data.comment }}</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group" id="commercial-status">
                  <label><strong>Estado Comercial
                    <Tooltip :parent="'commercial_status'" :message="'Estatus del Registro para Ventas.'">(*)</Tooltip>
                    : </strong>{{ data.status_commercial?.name }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group" id="certificado-religioso">
                  <label><strong>Certificado Religioso
                    <Tooltip :parent="'certificado_religioso'"
                             :message="'Certificado Koser, Halal , vegano, u Ortodoxa.'">(*)
                    </Tooltip>
                    : </strong>{{ data.religious_certificate ? 'Si' : 'No' }}</label>
                </div>
              </div>
            </div>
            <div class="row m-auto">
              <div class="col-md-6">
                <div class="form-group">
                  <label><strong>Archivos Adjuntos
                    <Tooltip :parent="'archivos_adjuntos'" :message="'Lista de archivos adjuntos.'">(*)</Tooltip>
                    : </strong></label>
                  <ul>
                    <li :key="file.id" v-for="file in data.files"><a @click="downloadFile(file.id)"
                                                                     href="javascript:void(0);">{{ file.name }}</a><a
                        href="javascript:void(0);" @click="deleteFile(file.id)"> &#10005;</a></li>
                  </ul>
                  <form novalidate @submit.prevent="onSubmit">
                    <file-upload id="fileupload" v-model="form.file" @change="adjuntarDoc"
                                 style="display: none;"></file-upload>
                  </form>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 next-btn mt-5 mb-4">
                <a @click="openFileUploader" href="javascript:void(0)" class="btn-white" v-if="rol=='Admin'">Adjuntar
                  Archivo</a>
                <a @click="steps" href="javascript:void(0)" class="btn-white" v-if="rol=='Admin'">Steps</a>
                <a @click="confirmDelete" href="javascript:void(0)" class="btn-white" v-if="rol=='Admin'">Eliminar</a>
                <a @click="editar" href="javascript:void(0)" class="btn-white" v-if="rol=='Admin'">Editar</a>
                <a @click="volverListado" href="javascript:void(0)" class="btn-white">Volver al listado</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
</template>
<script>
import {route} from "@/enviroment"
import axios from 'axios'
import FileUpload from "../common/FileUpload.vue"
import Tooltip from "../common/Tooltip.vue"

export default {
  name: "RegistroSanitarioDetalle",
  props: {},
  components: {
    FileUpload,
    Tooltip
  },
  data() {
    return {
      route: route,
      items: [],
      data: {},
      rol: 'User',
      form: {
        file: null
      }
    }
  },
  created() {
    this.getUser();
    this.getRegistro();
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    volverListado() {
      location.href = route + 'registro-sanitario';
    },
    getRegistro() {
      axios.get('/registro-sanitario/' + this.$route.params.id
      ).then((response) => {
        this.data = response.data.data;
      })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    editar() {
      location.href = route + 'registro-sanitario-edicion/' + this.$route.params.id;
    },
    async confirmDelete() {
      const confirmed = await this.confirmDeleteDialog();
      if (!confirmed) {
        return;
      }
      axios.delete('/registro-sanitario/' + this.$route.params.id
      ).then(() => {
        location.href = route + 'registro-sanitario';
      }).catch((error) => {
        if (error.code == "ERR_BAD_REQUEST") {
          location.href = route + "login";
        }
      })
    },
    async confirmDeleteDialog() {
      return await this.$vbsModal.confirm({
        title: "Eliminar el registro",
        message: "Esta seguro que desea eliminar el registro?",
        leftBtnText: 'No',
        rightBtnText: 'Si',
        displayCloseBtn: false
      });
    },
    adjuntarDoc() {
      axios.post(`/file/${this.$route.params.id}`, this.form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(() => {
            this.getRegistro();
            this.showAlert('success', 'Exito!', `Se subio el archivo correctamente.`);
          })
          .catch(() => {
            this.showAlert('warning', 'Atencion!', `Ocurrio un error al subir el archivo.`);
          });
    },
    steps() {
      location.href = route + 'registro-sanitario-steps/' + this.$route.params.id;
    },
    getUser() {
      axios.get('/auth/user'
      ).then((response) => {
        this.user = response.data.data.profile.name;
        const adminRol = response.data.data.profile.Admin_RS ?? null;
        const userRol = response.data.data.profile.User_RS ?? null;
        if (adminRol || userRol) {
          this.rol = adminRol ? 'Admin' : 'User';
        } else {
          location.href = route + "login";
        }
      })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = route + "login";
            }
          });
    },
    downloadFile(id) {
      let url = (process.env.NODE_ENV == 'production') ? '../api/' : process.env.VUE_APP_BACKEND_API_URL;
      url += `file/${id}`;
      window.open(url);
    },
    async deleteFile(id) {
      const confirm = await this.$vbsModal.confirm({
        title: "Eliminar el archivo",
        message: "Esta seguro que desea eliminar el archivo?",
        leftBtnText: 'No',
        rightBtnText: 'Si',
        displayCloseBtn: false
      });
      if (confirm) {
        //delete
        axios.delete(`/file/${id}`
        ).then(() => {
          this.getRegistro();
          this.showAlert('success', 'Exito!', `Se elimino el archivo correctamente.`);
        })
            .catch(() => {
              this.showAlert('warning', 'Atencion!', `Ocurrio un error.`);
            });
      }
      return false;
    },
    openFileUploader() {
      document.getElementById('fileupload').click()
    }

  }

}
</script>