/* eslint-disable */
<template>
  <section class="pedido-steps">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title">
            <h4>{{ $t('tdc.details.title') }}</h4>
            <a @click="closeRequestDetails()" href="javascript:void(0)">
              <img class="d-none d-sm-block" :src="require('@/assets/img/backspace.svg')" alt="backspace">
            </a>
          </div>
        </div>
      </div>
      <div v-if="loadingRequest" class="loading"></div>
      <div v-if="!loadingRequest" class="row mostrarPedido">
        <div class="col-md-12">
          <div class="confirmado p-3">
            <div v-if="!request.request_number" class="row">
              <div class="col-12">
                <div class="section-title">
                  <p class="subtitulo">{{ $t('tdc.details.not_found') }}</p>
                </div>
              </div>
            </div>
            <div v-if="!!request.request_number" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.creation_date') }}: </strong>{{ request.creation_date }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.request_number') }}: </strong>{{ request.request_number }}</label>
                </div>
              </div>
              <div v-if="isApprovalRole" class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.created_by') }}: </strong>{{ request.created_by }}</label>
                </div>
              </div>
            </div>
            <div v-if="!!request.request_number" class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.market') }}: </strong>{{ request.market }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.countries') }}: </strong>{{ request.country }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.sources') }}: </strong>{{ request.source }}</label>
                </div>
              </div>
              <div class="col-md-8">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.sku') }}: </strong>{{ formatSkuLabel(request) }}</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.quantity') }}: </strong>{{ request.quantity }}</label>
                </div>
              </div>
            </div>
            <div v-if="!!request.request_number" class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.status') }}: </strong>{{ request.status }}</label>
                </div>
              </div>
              <div v-if="isApprovalRole" class="col-md-4">
                <div class="form-group">
                  <label><strong>{{ request.approval_label }}: </strong>{{ request.approval_by }}</label>
                </div>
              </div>
            </div>
            <div v-if="!!request.request_number" class="row">
              <div class="col-md-12">
                <div class="confirmado mt-2">
                  <!--Para desktop-->
                  <div class="scrollable-table-container">
                    <div class="table-wrapper">
                      <table class="bg-white d-none d-sm-table large-table" id="pedidosTable">
                        <thead>
                        <tr style="background-color: #fffadc;">
                          <th class="text-center">{{ $t('tdc.icp_kg') }}</th>
                          <th class="text-center">{{ $t('tdc.export_charges') }}</th>
                          <th class="text-center">{{ $t('tdc.weight') }}</th>
                          <th class="text-center">{{ $t('tdc.carrier') }}</th>
                          <th class="text-center">{{ $t('tdc.total_rate') }}</th>
                          <th class="text-center">{{ $t('tdc.product_cost_freight') }}</th>
                          <th class="text-center">{{ $t('tdc.porcent_import_duties') }}</th>
                          <th class="text-center">{{ $t('tdc.duties') }}</th>
                          <th class="text-center">{{ $t('tdc.in_land_in_country_rate') }}</th>
                          <th class="text-center">{{ $t('tdc.duties_in_land_in_country') }}</th>
                          <th class="text-center">{{ $t('tdc.tdc_act') }}</th>
                        </tr>
                        </thead>
                        <tbody id="mostrarTablas">
                        <tr class="b-top registro-row">
                          <td class="text-center">{{ this.formatNumber(request.icp_kg) }}</td>
                          <td class="text-center">{{ this.formatNumber(request.export_charges) }}</td>
                          <td class="text-center">{{ request.weight }}</td>
                          <td class="text-center">{{ request.carrier }}</td>
                          <td class="text-center">{{ this.formatNumber(request.total_rate) }}</td>
                          <td class="text-center">{{ this.formatNumber(request.product_cost_freight) }}</td>
                          <td class="text-center">{{ request.porcent_import_duties }}</td>
                          <td class="text-center">{{ this.formatNumber(request.duties) }}</td>
                          <td class="text-center">{{ request.in_land_in_country_rate }}</td>
                          <td class="text-center">{{ this.formatNumber(request.duties_in_land_in_country) }}</td>
                          <td class="text-center">{{ this.formatNumber(request.tdc_act) }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <!--Para mobile hay que hacer una tabla por pedido-->
                  <table class="bg-white d-block d-sm-none pedidosTableMobile b-top registro-row">
                    <tr>
                      <th class="text-center">{{ $t('tdc.icp_kg') }}</th>
                      <td class="text-center">{{ formatNumber(request.icp_kg) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.export_charges') }}</th>
                      <td class="text-center">{{ formatNumber(request.export_charges) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.weight') }}</th>
                      <td class="text-center">{{ request.weight }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.carrier') }}</th>
                      <td class="text-center">{{ request.carrier }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.total_rate') }}</th>
                      <td class="text-center">{{ formatNumber(request.total_rate) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.product_cost_freight') }}</th>
                      <td class="text-center">{{ formatNumber(request.product_cost_freight) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.porcent_import_duties') }}</th>
                      <td class="text-center">{{ request.porcent_import_duties }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.duties') }}</th>
                      <td class="text-center">{{ formatNumber(request.duties) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.in_land_in_country_rate') }}</th>
                      <td class="text-center">{{ request.in_land_in_country_rate }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.duties_in_land_in_country') }}</th>
                      <td class="text-center">{{ formatNumber(request.duties_in_land_in_country) }}</td>
                    </tr>
                    <tr>
                      <th class="text-center">{{ $t('tdc.tdc_act') }}</th>
                      <td class="text-center">{{ formatNumber(request.tdc_act) }}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-if="!!request.request_number" class="row m-auto">
              <div class="col-md-12">
                <div class="form-group">
                  <label><strong>{{ $t('tdc.comments') }}: </strong></label>
                  <label v-for="(c, i) in request.comments" :key="i">{{ c.comment }}</label>
                </div>
              </div>
            </div>
            <div v-if="loading" class="loading"></div>
            <div class="row">
              <div class="col-md-12 next-btn mt-5 mb-4">
                <a @click="closeRequestDetails" href="javascript:void(0)" class="btn-green">
                  {{ $t('tdc.requests.title') }}
                </a>
                <a v-if="!isApprovalRole" @click="newRequest" href="javascript:void(0)" class="btn-green">
                  {{ $t('tdc.requests.create') }}
                </a>
                <a v-if="request.approval && isApprovalRole" @click="approval('approved')" href="javascript:void(0)" class="btn-green">
                  {{ $t('tdc.requests.approve') }}
                </a>
                <a  v-if="request.approval && isApprovalRole" @click="approval('canceled')" href="javascript:void(0)" class="btn-white">
                  {{ $t('tdc.requests.cancel') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <vue-basic-alert :duration="700" :closeIn="7000" ref="alert"/>
</template>
<script>
import {route} from "@/enviroment"
import axios from "axios";
import {TDC_ROLES} from "@/router";

export default {
  name: "tdcRequestDetails",
  props: {
    selectedRequest: Object,
    currentPage: Number,
    perPage: Number
  },
  emits: ['updateSelectedRequest', 'closeRequestDetails'],
  components: {
  },
  data() {
    return {
      route: route,
      role: '',
      request: {},
      id_tdc_form: '',
      loadingRequest: false,
      loading: false
    }
  },
  async created() {
    await this.initRequest();
  },
  computed: {
    isApprovalRole() {
      const roles = TDC_ROLES.filter(r => r !== 'requisitor');
      const profile = JSON.parse(sessionStorage.getItem('mccain_service_user_profile'));
      return profile && roles.includes(profile.tdcprofile.toString().toLowerCase());
    },
  },
  methods: {
    showAlert(type, title, message) {
      if (this.$refs.alert) {
        this.$refs.alert.showAlert(
            type, // There are 4 types of alert: success, info, warning, error
            message, // Message of the alert
            title, // Header of the alert
            {
              iconSize: 35, // Size of the icon (px)
              iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
              position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            }
        )
      }
    },
    initRequest() {
      this.id_tdc_form = this.$route.params.id_tdc_form;
      if (this.id_tdc_form) {
        this.loadingRequest = true;
        const id_tdc_form = this.id_tdc_form;
        const query = new URLSearchParams({id_tdc_form}).toString();
        axios
            .get(`/tdc/request/details?${query}`)
            .then(async (res) => {
              if (res.status === 200 && res.data.status === 'success') return res.data.data;
              else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
            })
            .then(async (data) => {
              if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
              this.request = data.request;
              this.loadingRequest = false;
            })
            .catch((error) => {
              this.request = {};
              this.loadingRequest = false;
              const message = error.status && error.status === 200
                  ? this.handleErrorI18n(error.message)
                  : this.handleErrorI18n(error);
              this.showAlert('warning', this.$t('errors.title'), message);
            });
      } else if (this.selectedRequest) {
        this.request = this.selectedRequest;
      } else {
        this.request = {};
        this.showAlert('warning', this.$t('errors.title'), this.handleErrorI18n(''));
      }
    },
    formatSkuLabel(request) {
      return request ? `${request.sku_sap} - ${request.sku_name}` : '';
    },
    formatNumber(value, position = 4) {
      if (!value) return '';
      const strValue = value.toString();
      const decimalPosition = strValue.indexOf('.');
      return decimalPosition === -1 || decimalPosition + position >= strValue.length
          ? strValue
          : strValue.substring(0, decimalPosition + position);
    },
    closeRequestDetails() {
      if (this.loading) return;
      if (this.id_tdc_form || !this.request) location.href = route + 'tdc';
      else {
        this.$emit("updateSelectedRequest", this.request);
        this.$emit("closeRequestDetails", false);
      }
    },
    newRequest() {
      if (this.loading) return;
      location.href = route + 'tdc-crear-solicitud';
    },
    approval(status) {
      if (this.loading) return;
      this.loading = true;
      axios
          .post(
              '/tdc/approval',
              {
                "id_tdc_form": this.request.id_tdc_form,
                "approval": status,
                "currentPage": this.currentPage,
                "perPage": this.perPage,
              }
          )
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.request.status = data.request.status
            this.request.approvals = data.request.approvals
            this.request.approval = data.request.approval
            this.request.approval_by = data.request.approval_by
            this.request.approval_label = data.request.approval_label
            this.sendApprovalNotifications()
            this.loading = false;
          })
          .catch((error) => {
            let message = '';
            this.loading = false;
            if (error.response.data.status === 'error') message = error.response.data.message ?? '';
            else if (error.status && error.status === 200) message = error.message ?? '';
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    sendApprovalNotifications() {
      if (!this.request) return;
      axios
          .post(
              '/tdc/approval/notifications',
              {
                "requests": [this.request]
              }
          )
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
            this.showAlert('info', this.$t('global.alert_info_title'), this.$t('tdc.notified'));
          })
          .catch((error) => {
            this.requests = [];
            this.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
  },
}
</script>

<style scoped>
.scrollable-table-container {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.table-wrapper {
  width: auto; /* Set a fixed width to enable scrolling */
}

.large-table {
  width: 100%;
  border-collapse: collapse;
}

.large-table th,
.large-table td {
  border: 1px solid #ccc;
}

.pedido-listado .confirmado table th {
  padding: 28px;
}

.pedido-listado .confirmado table td {
  padding: 28px;
}
</style>