/* eslint-disable */
<template>
    <section class="pedidos-section">
        <div class="container">
            <div class="section-title">
                <h4>{{ $t('home.title') }}</h4>
            </div>
            <div class="row">
              <div v-if="this.rs" class="col-md-4 index-option">
                <div class="box-pedido">
                  <router-link :to="route + 'registro-sanitario'">
                    <img :src="require('@/assets/img/pedidos1.png')" alt="pedidos1">
                    <h3>Registros Sanitarios</h3>
                  </router-link>
                </div>
              </div>
              <div v-if="this.tdc" class="col-md-4 index-option">
                <div class="box-pedido">
                  <router-link :to="route + 'tdc'">
                    <img :src="require('@/assets/img/pedidos3.png')" alt="pedidos3">
                    <h3>{{ $t('home.tdc') }}</h3>
                  </router-link>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>
<script>
import {route} from "@/enviroment"
import axios from "axios";
import {TDC_ROLES} from "@/router";

export default {
  name: "HomePage",
  props: {},
  data() {
    return {
      route: route,
      rs: false,
      tdc: false
    }
  },
  async created() {
    await this.getUserRole();
  },
  methods: {
    getUserRole() {
      const profile = sessionStorage.getItem('mccain_service_user_profile');
      if (profile) {
        const data = JSON.parse(profile);
        this.rs = !!data.Admin_RS || !!data.User_RS;
        this.tdc = data.tdcprofile && TDC_ROLES.includes(data.tdcprofile.toString().toLowerCase());
      }
    },
    logout() {
      axios.get(
          '/auth/logout'
      ).then(() => {
        window.localStorage.clear();
        location.href = route + "login";
      });
    },
  },
}
</script>