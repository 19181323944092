import {createRouter, createWebHistory} from 'vue-router';
import IngresoComponent from '../components/pages/IngresoComponent.vue';
import ForgotPassword from '../components/auth/ForgotPassword.vue';
import ResetPassword from '../components/auth/ResetPassword.vue';
import Home from '../components/pages/Home.vue';
import RegistroSanitarioListado from '../components/pages/registro_sanitario/RegistroSanitarioListado.vue';
import RegistroSanitarioNuevo from '../components/pages/registro_sanitario/RegistroSanitarioNuevo.vue';
import RegistroSanitarioEdicion from '../components/pages/registro_sanitario/RegistroSanitarioEdicion.vue';
import RegistroSanitarioDetalle from '../components/pages/registro_sanitario/RegistroSanitarioDetalle.vue';
import RegistroSanitarioSteps from '../components/pages/registro_sanitario/RegistroSanitarioSteps.vue';
import tdcRequests from "@/components/pages/tdc/tdcRequests";
import tdcRequestForm from "@/components/pages/tdc/tdcRequestForm";
import tdcRequestDetails from "@/components/pages/tdc/tdcRequestDetails";
import {route} from "@/enviroment"
import axios from 'axios';

export const TDC_ROLES = ['requisitor', 'finanzas', 'comercial', 'demand_planner', 'supply_director'];

const routes = [
    {
        path: route + 'login',
        name: 'login',
        component: IngresoComponent,
        meta: {requiresAuth: false}
    },
    {
        path: route + '',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
        meta: {requiresAuth: false}
    },
    {
        path: route + 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {requiresAuth: false}
    },
    {
        path: route + 'registro-sanitario/',
        name: 'registro-sanitario',
        component: RegistroSanitarioListado,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'registro-sanitario-nuevo',
        name: 'registro-sanitario-nuevo',
        component: RegistroSanitarioNuevo,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'registro-sanitario-detalle/:id',
        name: 'registro-sanitario-detalle',
        component: RegistroSanitarioDetalle,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'registro-sanitario-edicion/:id',
        name: 'registro-sanitario-edicion',
        component: RegistroSanitarioEdicion,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'registro-sanitario-steps/:id',
        name: 'registro-sanitario-steps',
        component: RegistroSanitarioSteps,
        meta: {requiresAuth: true}
    },
    {
        path: route + 'tdc/',
        name: 'tdc',
        component: tdcRequests,
        meta: {
            requiresAuth: true,
            requiresTDC: true,
        }
    },
    {
        path: route + 'tdc-crear-solicitud',
        name: 'tdc-crear-solicitud',
        component: tdcRequestForm,
        meta: {
            requiresAuth: true,
            requiresTDC: true,
        }
    },
    {
        path: route + 'tdc-solicitud-detalles/:id_tdc_form',
        name: 'tdc-solicitud-detalles',
        component: tdcRequestDetails,
        meta: {
            requiresAuth: true,
            requiresTDC: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

// check for user logged in on auth sections
router.beforeEach((to, from, next) => {
    try {
        const profile = JSON.parse(sessionStorage.getItem('mccain_service_user_profile'));
        const expireTime = JSON.parse(sessionStorage.getItem('mccain_service_expire_session'));

        if (to.meta.requiresAuth) {
            if (!profile || !expireTime || Date.now() > expireTime) {
                clearSessionData();
                return redirectToPath(to, next, 'login'); // Use return to stop further execution
            }
        }

        if (to.meta.requiresTDC) {
            if (!profile) {
                return redirectToPath(to, next, 'login'); // Use return to stop further execution
            }

            if (!profile.tdcprofile || !TDC_ROLES.includes(profile.tdcprofile.toString().toLowerCase())) {
                return redirectToPath(to, next, 'home'); // Use return to stop further execution
            }
        }

        next(); // Only call next() once if all checks pass

    } catch (error) {
        console.error("Error in navigation guard:", error);
        clearSessionData();
        return redirectToPath(to, next, 'login'); // Use return to stop further execution
    }
});

// Helper function to clear session data
export function clearSessionData() {
    sessionStorage.removeItem('mccain_service_country');
    sessionStorage.removeItem('mccain_service_user_profile');
    sessionStorage.removeItem('mccain_service_expire_session');
}

// Helper function to redirect to login page
function redirectToPath(to, next, path = 'login') {
    next({
        path: route + path,
        query: {redirect: to.fullPath}
    });
}

//handle 401 response -> redirect
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (error.response.config.url.includes('login')) {
            return Promise.reject(error);
        }
        location.href = route + "login";
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        config.withCredentials = true;
        config.baseURL = process.env.VUE_APP_BACKEND_API_URL;
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export default router;
