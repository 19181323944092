import {createI18n} from 'vue-i18n';

const messages = {
  es: {
    global: {
      alert_info_title: 'Información!',
      placeholder_select: 'Seleccione',
      btn_filter: 'Filtrar',
      btn_clean: 'Limpiar',
      btn_compute: 'Calcular',
      btn_download: 'Descargar',
      btn_save: 'Guardar',
      btn_cancel: 'Cancelar',
      pagination: {
        first: 'Primero',
        prev: 'Anterior',
        next: 'Siguiente',
        last: 'Último',
      }
    },
    header: {
      home: 'Inicio',
      signOut: 'Cerrar Sesión',
      signingOut: 'Cerrando sesión ...',
    },
    footer: {
      'copyright': 'Todos los derechos reservados. Política de privacidad global.'
    },
    home: {
      title: 'Servicios',
      tdc: 'Herramienta TDC & SWAP',
    },
    tdc: {
      request_number: 'Número de Solicitud',
      market: 'Sales Office',
      countries: 'Source',
      sources: 'Source Point',
      divisions: 'División',
      categories: 'Categoría',
      subcategories: 'Subcategoría',
      families: 'Familia',
      formTypes: 'Tipo',
      sku: 'SKU',
      quantity: 'Quantity (MT)',
      creation_date: 'Fecha de Creación',
      created_by: 'Creado por',
      status: 'Status',
      users: 'Usuario',
      icp_kg: 'ICP / KG',
      export_charges: 'Export Charges',
      weight: 'Weight',
      carrier: 'Carrier',
      total_rate: 'Total Rate',
      product_cost_freight: 'Product Cost & Freight',
      porcent_import_duties: '% Import Duties',
      duties: 'Duties',
      in_land_in_country_rate: 'In-Land In Country Rate',
      duties_in_land_in_country: 'Duties and In-land in-country',
      tdc_act: 'TDC',
      comments: 'Comentarios',
      requests: {
        title: 'Consulta de Solicitudes',
        create: 'Crear nueva solicitud',
        approve: 'Aprobar solicitud',
        cancel: 'Cancelar solicitud',
      },
      details: {
        title: 'Detalles de Solicitud',
        not_found: 'El registro solicitado no fue encontrado.',
      },
      form: {
        title: 'Creación de Solicitud',
        subtitle: 'En esta sección puede crear una nueva solicitud',
        submitted: 'Tu solicitud fue registrada con éxito, es posible acompañar su status de aprobación desde la sección de "Consulta de Solicitudes".',
        validation: {
          select_single_choice: 'Debe seleccionar un',
          select_multiple_choice: 'Debe seleccionar al menos un',
          required_quantity: 'Debe ingresar una',
          select_multiple_requests: 'Debe seleccionar al menos un fila de la tabla.',
          required_comments: 'Debe agregar comentarios.',
        }
      },
      notified: 'Se ha notificado al perfil de aprobación correspondiente el estado de la solicitud.',
    },
    errors: {
      title: 'Atención!',
      required_field: 'Este campo es obligatorio.',
      invalid_email: 'Por favor, ingrese una dirección de correo electrónico válida.',
      password_mismatch: 'Las contraseñas no coinciden.',
      server_error: 'Ocurrió un error en el servidor. Por favor, inténtelo de nuevo más tarde.',
      not_found: 'El recurso solicitado no fue encontrado (404).',
      forbidden: 'No tienes permiso para acceder a este recurso (403).',
      unknown_error: 'Ocurrió un error desconocido. Por favor, inténtelo de nuevo más tarde.'
    }
  },
  en: {
    global: {
      alert_info_title: 'Information!',
      placeholder_select: 'Select',
      btn_filter: 'Filter',
      btn_clean: 'Clear',
      btn_compute: 'Compute',
      btn_download: 'Download',
      btn_save: 'Save',
      btn_cancel: 'Cancel',
      pagination: {
        first: 'First',
        prev: 'Previous',
        next: 'Next',
        last: 'Last',
      }
    },
    header: {
      home: 'Home',
      signOut: 'Sign Out',
      signingOut: 'Signing out ...',
    },
    footer: {
      copyright: 'All rights reserved. Global privacy policy.'
    },
    home: {
      title: 'Services',
      tdc: 'TDC & SWAP Tool',
    },
    tdc: {
      request_number: 'Request Number',
      market: 'Sales Office',
      countries: 'Source',
      sources: 'Source Point',
      divisions: 'Division',
      categories: 'Category',
      subcategories: 'Subcategory',
      families: 'Family',
      formTypes: 'Type',
      sku: 'SKU',
      quantity: 'Quantity (MT)',
      creation_date: 'Creation Date',
      created_by: 'Created by',
      status: 'Status',
      users: 'User',
      icp_kg: 'ICP / KG',
      export_charges: 'Export Charges',
      weight: 'Weight',
      carrier: 'Carrier',
      total_rate: 'Total Rate',
      product_cost_freight: 'Product Cost & Freight',
      porcent_import_duties: '% Import Duties',
      duties: 'Duties',
      in_land_in_country_rate: 'In-Land In Country Rate',
      duties_in_land_in_country: 'Duties and In-land in-country',
      tdc_act: 'TDC',
      comments: 'Comments',
      requests: {
        title: 'Request Consultation',
        create: 'Create New Request',
        approve: 'Approve Request',
        cancel: 'Cancel Request',
      },
      details: {
        title: 'Request Details',
        not_found: 'The requested record was not found.',
      },
      form: {
        title: 'Request Creation',
        subtitle: 'In this section, you can create a new request',
        submitted: 'Your request was successfully registered. You can track its approval status in the "Request Consultation" section.',
        validation: {
          select_single_choice: 'You must select one.',
          select_multiple_choice: 'You must select at least one.',
          required_quantity: 'You must enter a quantity.',
          select_multiple_requests: 'You must select at least one row from the table.',
          required_comments: 'You must add comments.',
        }
      },
      notified: 'The corresponding approval profile has been notified of the status of the request.',
    },
    errors: {
      title: 'Attention!',
      required_field: 'This field is required.',
      invalid_email: 'Please enter a valid email address.',
      password_mismatch: 'Passwords do not match.',
      server_error: 'A server error occurred. Please try again later.',
      not_found: 'The requested resource was not found (404).',
      forbidden: 'You are not allowed to access this resource (403).',
      unknown_error: 'An unknown error occurred. Please try again later.'
    }
  },
  pt: {
    global: {
      alert_info_title: 'Información!',
      placeholder_select: 'Selecione',
      btn_filter: 'Filtrar',
      btn_clean: 'Limpar',
      btn_compute: 'Calcular',
      btn_download: 'Baixar',
      btn_save: 'Guardar',
      btn_cancel: 'Cancelar',
      pagination: {
        first: 'Primeiro',
        prev: 'Anterior',
        next: 'Próximo',
        last: 'Último',
      }
    },
    header: {
      home: 'Início',
      signOut: 'Sair',
      signingOut: 'Saindo ...',
    },
    footer: {
      copyright: 'Todos os direitos reservados. Política de privacidade global.'
    },
    home: {
      title: 'Serviços',               // Portuguese translation
      tdc: 'Ferramenta TDC & SWAP',    // Portuguese translation
    },
    tdc: {
      request_number: 'Número do Pedido',
      market: 'Sales Office',
      countries: 'Source',
      sources: 'Source Point',
      divisions: 'Divisão',
      categories: 'Categoria',
      subcategories: 'Subcategoria',
      families: 'Família',
      formTypes: 'Tipo',
      sku: 'SKU',
      quantity: 'Quantity (MT)',
      creation_date: 'Data de Criação',
      created_by: 'Creado por',
      status: 'Status',
      users: 'Usuário',
      icp_kg: 'ICP / KG',
      export_charges: 'Export Charges',
      weight: 'Weight',
      carrier: 'Carrier',
      total_rate: 'Total Rate',
      product_cost_freight: 'Product Cost & Freight',
      porcent_import_duties: '% Import Duties',
      duties: 'Duties',
      in_land_in_country_rate: 'In-Land In Country Rate',
      duties_in_land_in_country: 'Duties and In-land in-country',
      tdc_act: 'TDC',
      comments: 'Comentarios',
      requests: {
        title: 'Consulta de Pedidos',
        create: 'Criar Novo Pedido',
        approve: 'Aprovar Pedido',
        cancel: 'Cancelar Pedido',
      },
      details: {
        title: 'Detalhes do Pedido',
        not_found: 'O registro solicitado não foi encontrado.',
      },
      form: {
        title: 'Criação de Pedido',
        subtitle: 'Nesta seção, você pode criar um novo pedido',
        submitted: 'Seu pedido foi registrado com sucesso. Você pode acompanhar o status de aprovação na seção "Consulta de Pedidos".',
        validation: {
          select_single_choice: 'Você deve selecionar um.',
          select_multiple_choice: 'Você deve selecionar pelo menos um.',
          required_quantity: 'Você deve inserir uma quantidade.',
          select_multiple_requests: 'Você deve selecionar pelo menos uma linha da tabela.',
          required_comments: 'Você deve adicionar comentários.',
        }
      },
      notified: 'O perfil de aprovação correspondente foi notificado sobre o status da solicitação.',
    },
    errors: {
      title: 'Atención!',
      required_field: 'Este campo é obrigatório.',
      invalid_email: 'Por favor, insira um endereço de email válido.',
      password_mismatch: 'As senhas não correspondem.',
      server_error: 'Ocorreu um erro no servidor. Tente novamente mais tarde.',
      not_found: 'O recurso solicitado não foi encontrado (404).',
      forbidden: 'Você não tem permissão para acessar este recurso (403).',
      unknown_error: 'Ocorreu um erro desconhecido. Tente novamente mais tarde.'
    }
  },
};

let locale = '';
const defaultLocale = window.$cookies.get('mccain_service_user_language') || 'es';
switch (defaultLocale.toString().toLowerCase()) {
  case 'portuguese':
  case 'pt':
    locale = 'pt';
    break;
  case 'english':
  case 'en':
    locale = 'en';
    break;
  case 'spanish':
  case 'es':
  default:
    locale = 'es';
    break;
}

const i18n = createI18n({
  locale: locale, // set locale from cookies
  fallbackLocale: 'es', // fallback locale
  messages, // set locale messages
});

export default i18n;
