import "vue-multiselect/dist/vue-multiselect.css"
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import Modal from "vue-bs-modal";
import Datepicker from '@vuepic/vue-datepicker';
import VueBasicAlert from 'vue-basic-alert'
import '@vuepic/vue-datepicker/dist/main.css'
import VueCookies from 'vue-cookies';
import i18n from "@/assets/i18n/locale";
import VueMultiselect from 'vue-multiselect'
import errorMixin from "@/assets/js/errorMixin";

const app = createApp(App);

app
.use(router)
.use(Modal)
.use(VueCookies)
.use(i18n)
.component('Datepicker', Datepicker)
.component('VueBasicAlert', VueBasicAlert)
.component('VueMultiselect', VueMultiselect)
.mixin(errorMixin)
.mount('#app')

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./sw.js');
    });
}