<template>
  <div class="pedido-listado">
    <section class="pedido-steps" v-if="!showRequestDetails">
      <div class="container">
        <div class="row mt-3">
          <div class="col-md-12">
            <a v-if="!isApprovalRole" href="javascript:void(0)" @click="newRequest" class="btn-green">
              {{ $t('tdc.requests.create') }}
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="section-title">
              <h5 class="pull-left">{{ $t('tdc.requests.title') }}</h5>
              <router-link :to="route">
                <img :src="require('@/assets/img/backspace.svg')" alt="backspace">
              </router-link>
            </div>
          </div>
        </div>
        <form class="form-filter mt-3">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.request_number') }}</label>
                <input type="text" v-model="filter.request_number">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.market') }}</label>
                <VueMultiselect
                    v-model="filter.market"
                    :options="markets.options"
                    :track-by="'market'"
                    :label="'market'"
                    :multiple="false"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="markets.loading"
                    :placeholder="$t('global.placeholder_select')"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.countries') }}</label>
                <VueMultiselect
                    v-model="filter.countries"
                    :options="countries.options"
                    :track-by="'country'"
                    :label="'country'"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="countries.loading"
                    :disabled="markets.loading || !filter.market"
                    :placeholder="$t('global.placeholder_select')"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.sources') }}</label>
                <VueMultiselect
                    v-model="filter.sources"
                    :options="sources.options"
                    :track-by="'source'"
                    :label="'source'"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="sources.loading"
                    :disabled="markets.loading || !filter.market || countries.loading || filter.countries.length === 0"
                    :placeholder="$t('global.placeholder_select')"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.creation_date') }}</label>
                <Datepicker
                    v-model="filter.creation_date"
                    name="creation-date"
                    :enableTimePicker="false"
                    textInput
                    autoApply
                    format="dd/MM/yyyy"
                    inputClassName="dp-input-custom"
                    utc="preserve"
                />
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.status') }}</label>
                <VueMultiselect
                    v-model="filter.status"
                    :options="status.options"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="status.loading"
                    :placeholder="$t('global.placeholder_select')"
                />
              </div>
            </div>
            <div v-if="isApprovalRole" class="col-md-3">
              <div class="form-group">
                <label>{{ $t('tdc.users') }}</label>
                <VueMultiselect
                    v-model="filter.users"
                    :options="users.options"
                    :track-by="'email'"
                    :label="'email'"
                    :multiple="true"
                    :taggable="true"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    :loading="users.loading"
                    :placeholder="$t('global.placeholder_select')"
                />
              </div>
            </div>
            <div class="col-md-12 next-btn">
              <a href="javascript:void(0)" @click="getRequests()" class="btn-green">{{ $t('global.btn_filter') }}</a>
              <a @click="cleanFilter" href="javascript:void(0)" class="btn-white">{{ $t('global.btn_clean') }}</a>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                <tr style="background-color: #fffadc;">
                  <th class="text-center">{{ $t('tdc.request_number') }}
                    <Tooltip :parent="'request_number'" :message="$t('tdc.request_number')">(*)</Tooltip>
                  </th>
                  <th class="text-center">{{ $t('tdc.market') }}
                    <Tooltip :parent="'markets'" :message="$t('tdc.market')">(*)</Tooltip>
                  </th>
                  <th class="text-center">{{ $t('tdc.countries') }}
                    <Tooltip :parent="'countries'" :message="$t('tdc.countries')">(*)</Tooltip>
                  </th>
                  <th class="text-center">{{ $t('tdc.sources') }}
                    <Tooltip :parent="'sources'" :message="$t('tdc.sources')">(*)</Tooltip>
                  </th>
                  <th class="text-center">{{ $t('tdc.creation_date') }}
                    <Tooltip :parent="'creation_date'" :message="$t('tdc.creation_date')">(*)</Tooltip>
                  </th>
                  <th class="text-center">{{ $t('tdc.status') }}
                    <Tooltip :parent="'status'" :message="$t('tdc.status')">(*)</Tooltip>
                  </th>
                  <th v-if="isApprovalRole" class="text-center">{{ $t('tdc.users') }}
                    <Tooltip :parent="'approval_by'" :message="$t('tdc.users')">(*)</Tooltip>
                  </th>
                </tr>
                </thead>
                <tbody id="mostrarTablas">
                <tr class="b-top registro-row" v-for="(r, i) in requests" :key="i" @click="requestDetails(r)">
                  <td class="text-center">{{ r.request_number }}</td>
                  <td class="text-center">{{ r.market }}</td>
                  <td class="text-center">{{ r.country }}</td>
                  <td class="text-center">{{ r.source }}</td>
                  <td class="text-center">{{ r.creation_date }}</td>
                  <td class="text-center">{{ r.status }}</td>
                  <td v-if="isApprovalRole" class="text-center">{{ r.approval_by }}</td>
                </tr>
                </tbody>
              </table>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top registro-row"
                     v-for="(r, i) in requests" :key="i" @click="requestDetails(r)">
                <tr>
                  <th class="text-center">{{ $t('tdc.request_number') }}</th>
                  <td class="text-center">{{ r.request_number }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('tdc.market') }}</th>
                  <td class="text-center">{{ r.market }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('tdc.countries') }}</th>
                  <td class="text-center">{{ r.country }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('tdc.sources') }}</th>
                  <td class="text-center">{{ r.source }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('tdc.creation_date') }}</th>
                  <td class="text-center">{{ r.creation_date }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t('tdc.status') }}</th>
                  <td class="text-center">{{ r.status }}</td>
                </tr>
                <tr v-if="isApprovalRole">
                  <th class="text-center">{{ $t('tdc.users') }}</th>
                  <td class="text-center">{{ r.approval_by }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div v-if="loading" class="loading"></div>
        <div v-if="totalItems && totalPages" class="row">
          <div class="col-md-12 text-center mb-5">
            <div class="pagination-controls">
              <Vue3BsPaginate
                  v-model:modelValue="currentPage"
                  :total="totalItems"
                  :perPage="perPage"
                  :pagesShown="5"
                  @update:modelValue="changePage"
                  :firstJumpContent="firstJumpContent"
                  :prevContent="prevContent"
                  :nextContent="nextContent"
                  :lastJumpContent="lastJumpContent"
                  alignment="center"
                  size="sm"
                  showPrevNextButton
                  showJumpButtons
              />
<!--              <div class="pagination-info">-->
<!--                Página {{ currentPage }} de {{ totalPages }} ({{ totalItems }} solicitudes en total)-->
<!--              </div>-->
<!--              <div class="pagination-info">-->
<!--                <label for="perPage" class="mr-3">Solicitudes por página:</label>-->
<!--                <select id="perPage" v-model="perPage" @change="onPerPageChange">-->
<!--                  <option v-for="size in perPageOptions" :key="size" :value="size">{{ size }}</option>-->
<!--                </select>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 next-btn">
            <a href="javascript:void(0)" @click="exportCSV" class="btn-green">{{ $t('global.btn_download') }}</a>
          </div>
        </div>
      </div>
    </section>
    <tdc-request-details
        v-if="showRequestDetails"
        :selectedRequest="selectedRequest"
        :currentPage="currentPage"
        :perPage="perPage"
        @closeRequestDetails="closeRequestDetails"
        @updateSelectedRequest="updateSelectedRequest"
    ></tdc-request-details>
    <vue-basic-alert :duration="700" :closeIn="7000" ref="alert"/>
  </div>
</template>

<script>
import {route} from "@/enviroment";
import axios from "axios";
import Tooltip from "../common/Tooltip.vue"
import {Vue3BsPaginate} from "vue3-bootstrap-paginate";
import TdcRequestDetails from "@/components/pages/tdc/tdcRequestDetails";
import {TDC_ROLES} from "@/router";

export default {
  name: 'tdcRequests',
  components: {
    TdcRequestDetails,
    Tooltip,
    Vue3BsPaginate
  },
  data: function () {
    return {
      route: route,
      status: {
        options: [],
        loading: false
      },
      markets: {
        options: [],
        loading: false
      },
      countries: {
        options: [],
        loading: false
      },
      sources: {
        options: [],
        loading: false
      },
      users: {
        options: [],
        loading: false
      },
      filter: {
        request_number: '',
        creation_date: '',
        status: [],
        market: null,
        countries: [],
        sources: [],
        users: [],
      },
      requests: [],
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      perPageOptions: [5, 10, 20, 50, 100],
      loading: false,
      selectedRequest: {},
      showRequestDetails: false
    }
  },
  async created() {
    await this.getRequests();
    this.getStatusOptions();
    this.getMarketOptions();
    if (this.isApprovalRole) this.getUserOptions();
  },
  computed: {
    isApprovalRole() {
      const roles = TDC_ROLES.filter(r => r !== 'requisitor');
      const profile = JSON.parse(sessionStorage.getItem('mccain_service_user_profile'));
      return profile && roles.includes(profile.tdcprofile.toString().toLowerCase());
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage) || 1;
    },
    firstJumpContent() {
      return this.$t('global.pagination.first');
    },
    prevContent() {
      return this.$t('global.pagination.prev');
    },
    nextContent() {
      return this.$t('global.pagination.next');
    },
    lastJumpContent() {
      return this.$t('global.pagination.last');
    }
  },
  watch: {
    'filter.market': function (element) {
      this.countries.options = [];
      this.sources.options = [];
      this.filter.countries = [];
      this.filter.sources = [];
      if (element) this.getCountryOptions(element.market);
    },
    'filter.countries': function (elements) {
      this.sources.options = [];
      this.filter.sources = [];
      const countries = elements.map(item => item.country);
      if (countries.length > 0) {
        this.getSourceOptions(this.filter.market.market, countries);
      }
    }
  },
  methods: {
    showAlert(type, title, message) {
      if (this.$refs.alert) {
        this.$refs.alert.showAlert(
            type, // There are 4 types of alert: success, info, warning, error
            message, // Message of the alert
            title, // Header of the alert
            {
              iconSize: 35, // Size of the icon (px)
              iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
              position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
            }
        );
      }
    },
    getStatusOptions() {
      this.status.loading = true;
      axios
          .get('/tdc/status')
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.status.options = data.options;
            this.status.loading = false;
          })
          .catch((error) => {
            this.status.options = [];
            this.status.loading = false;
            const message = error.status && error.status === 200 
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getMarketOptions() {
      this.markets.loading = true;
      axios
          .get('/tdc/markets')
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.markets.options = data.markets;
            this.markets.loading = false;
          })
          .catch((error) => {
            this.markets.options = [];
            this.markets.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getCountryOptions(market) {
      this.countries.loading = true;
      const query = new URLSearchParams({market}).toString();
      axios
          .get(`/tdc/countries?${query}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.countries.options = data.countries;
            this.countries.loading = false;
          })
          .catch((error) => {
            this.countries.options = [];
            this.countries.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getSourceOptions(market, countries) {
      this.sources.options = [];
      this.sources.loading = true;
      const query = new URLSearchParams({market, countries}).toString();
      axios
          .get(`/tdc/sources?${query}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.sources.options = data.sources;
            this.sources.loading = false;
          })
          .catch((error) => {
            this.sources.options = [];
            this.sources.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    getUserOptions() {
      this.users.loading = true;
      axios
          .get('/tdc/users')
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.users.options = data.users;
            this.users.loading = false;
          })
          .catch((error) => {
            this.users.options = [];
            this.users.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    cleanFilter() {
      this.filter = {
        request_number: '',
        date_creation: '',
        status: [],
        markets: null,
        countries: [],
        sources: [],
        users: [],
      };
      this.currentPage = 1;
      this.perPage = 10;
      this.getRequests();
    },
    getFilterData(page) {
      const filterObject = JSON.parse(JSON.stringify(this.filter));

      if (filterObject.creation_date !== undefined && filterObject.creation_date !== null && !isNaN(Date.parse(filterObject.creation_date))) {
        filterObject.creation_date = (new Date(filterObject.creation_date)).toISOString().split('T')[0];
      } else if (isNaN(Date.parse(filterObject.creation_date))) filterObject.creation_date = '';

      filterObject.markets = filterObject.market ? [filterObject.market.market] : [];
      delete filterObject.market;

      filterObject.countries = filterObject.countries.map(item => item.country);

      filterObject.sources = filterObject.sources.map(item => item.source);

      if (this.isApprovalRole) {
        filterObject.emails = filterObject.users.map(item => item.email);
        delete filterObject.users;
      }

      filterObject.page = page;
      filterObject.perPage = this.perPage;

      return filterObject;
    },
    getRequestsFilterQuery(page) {
      const query = new URLSearchParams(this.getFilterData(page)).toString();
      return (query) ? `&${query}` : ``;
    },
    getRequests(page = 1) {
      if (this.loading) return;
      this.requests = [];
      this.currentPage = 1;
      this.loading = true;
      axios
          .get(`/tdc/requests?${this.getRequestsFilterQuery(page)}`)
          .then(async (res) => {
            if (res.status === 200 && res.data.status === 'success') return res.data.data;
            else throw new Error(this.handleErrorI18n(res.data.message ?? ''));
          })
          .then(async (data) => {
            if (data.status.toString() === 'error') throw new Error(this.handleErrorI18n(data.message ?? ''));
            this.requests = data.requests.data;
            this.currentPage = data.requests.current_page;
            this.totalItems = data.requests.total;
            this.loading = false;
          })
          .catch((error) => {
            this.requests = [];
            this.currentPage = 1;
            this.totalItems = 0;
            this.loading = false;
            const message = error.status && error.status === 200
                ? this.handleErrorI18n(error.message)
                : this.handleErrorI18n(error);
            this.showAlert('warning', this.$t('errors.title'), message);
          });
    },
    changePage(page) {
      this.currentPage = page;
      this.getRequests(page);
    },
    onPerPageChange() {
      this.getRequests(1); // Reset to first page on items per page change
    },
    requestDetails(request) {
      this.showRequestDetails = true;
      this.selectedRequest = request;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    closeRequestDetails(close) {
      this.showRequestDetails = close;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },
    updateSelectedRequest(request) {
      const index = this.requests.findIndex((r) => r.id_tdc_form === request.id_tdc_form);

      if (index === -1) {
        console.warn(`Request with id_tdc_form ${request.id_tdc_form} not found.`);
        return;
      }

      // Using spread operator to create a new array with the updated request
      this.requests = [
        ...this.requests.slice(0, index),
        request, // updated request
        ...this.requests.slice(index + 1)
      ];
    },
    newRequest() {
      location.href = route + 'tdc-crear-solicitud';
    },
    exportCSV() {
      let url = process.env.VUE_APP_BACKEND_API_URL;
      url += `tdc/export?${this.getRequestsFilterQuery(this.currentPage)}`;
      window.open(url, "_self");
    }
  }
}
</script>

<style scoped>

.pagination-info {
  margin-top: 10px;
}

</style>