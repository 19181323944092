import i18n from "@/assets/i18n/locale";

export default {
    methods: {
        setLanguage(languageCode) {
            let locale = '';
            switch (languageCode.toString().toLowerCase()) {
                case 'portuguese':
                case 'pt':
                    locale = 'pt';
                    break;
                case 'english':
                case 'en':
                    locale = 'en';
                    break;
                case 'spanish':
                case 'es':
                default:
                    locale = 'es';
                    break;
            }
            i18n.global.locale = locale;
        },
        handleErrorI18n(error) {
            switch (error) {
                case 'server_error':
                    return this.$t('errors.server_error');
                case 'not_found':
                    return this.$t('errors.not_found');
                case 'forbidden':
                    return this.$t('errors.forbidden');
                default:
                    return this.$t('errors.unknown_error');
            }
        }
    }
};
