<template>
  <div>
    <section style="background: black;">
      <!-- Place somewhere in the <body> of your page -->
      <div class="flexslider" style="border:0; margin:0">
        <ul class="slides">
          <li>
            <img :src="require('@/assets/img/home/bg-1.jpg')"/>
          </li>
          <li>
            <img :src="require('@/assets/img/home/bg-3.jpg')"/>
          </li>
          <li>
            <img :src="require('@/assets/img/home/bg-4.jpg')"/>
          </li>
          <li>
            <img :src="require('@/assets/img/home/bg-5.jpg')"/>
          </li>
        </ul>
      </div>
      <div class="overlay">
        <div class="container">
          <div class="row align-center">
            <div class="col-md-6 text-left">
              <div class="logo">
                <nav class="navbar navbar-expand-lg navbar-light">
                  <button class="navbar-toggler" type="button" data-toggle="collapse"
                          data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                          aria-expanded="false" aria-label="Toggle navigation">
                    <img :src="require('@/assets/img/menu.svg')">
                  </button>
                  <a class="navbar-brand" href="javascript:void(0)">
                    <img :src="require('@/assets/img/mccain_logo.png')">
                  </a>
                </nav>
              </div>
            </div>
            <!-- <div class="col-md-6 text-right">
              <ul class="navbar-desktop">
                <li><router-link class="menu-item" :to="route + 'contacto'">Contáctenos</router-link></li>
              </ul>
            </div> -->
          </div>
        </div>
        <div class="main-elements">
          <div class="container">
            <div class="row">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <router-link :to="route + 'contacto'" class="nav-link menu-item	" href="#">Contáctenos
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="col-md-8 self-center">
                <h1>Si está McCain <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sientes el sabor</h1>
              </div>
              <div class="col-md-4 self-center init-sesion">
                <div class="white-box">
                  <div class="text-center" v-if="!enable_login">
                    <h4>Seleccione país</h4>
                    <form @submit.prevent="onSubmit">
                      <div class="row">
                        <div class="col-md-12">
                          <select class="select-input" name="comunicacion" v-model="country"
                                  placeholder="Seleccione país" :disabled="loading">
                            <option v-for="(country,index) in this.countries" :key="index" :value="country">
                              {{ country }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <p v-if="this.error_message!=''" class="password-forget invalid-password" style="color: red;">
                        {{ error_message }}</p>
                      <button class="pointer" @click="setCountry" :disabled="loading">Continuar</button>
                      <div v-if="loading" class="spinner"></div>
                    </form>
                  </div>
                  <div class="text-center" v-if="enable_login">
                    <h4>Iniciar Sesión</h4>
                    <h6 style="font-size: 15px;">País: {{ country }}</h6>
                    <form @submit.prevent="onSubmit">
                      <div class="row">
                        <div class="col-md-12">
                          <input v-model="usuario" type="text" name="" placeholder="Usuario" :disabled="loading">
                        </div>
                        <div class="col-md-12">
                          <input v-model="password" type="password" placeholder="Contraseña" name=""
                                 :disabled="loading">
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <router-link v-if="!loading" :to="route + 'forgot-password'">
                            <p class="password-forget">¿Olvidó su contraseña?</p>
                          </router-link>
                          <p v-else class="password-forget">¿Olvidó su contraseña?</p>
                        </div>
                      </div>
                      <p v-if="this.error_message!=''" class="password-forget invalid-password" style="color: red;">
                        {{ error_message }}</p>
                      <button class="pointer" @click="login" :disabled="loading">Ingresar</button>
                    </form>
                    <div class="mt-2" v-if="!loading">
                      <a :href="route + 'login'" style="color: #1d1d1b; font-size: 14px;">
												<span>
													<img :src="require('@/assets/img/backspace.svg')">
													Volver a página de inicio
												</span>
                      </a>
                    </div>
                    <div v-if="loading" class="spinner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterCopyright></FooterCopyright>
  </div>
</template>

<script>
import FooterCopyright from '../layouts/FooterCopyright.vue'
import axios from 'axios'
import {route} from "@/enviroment"

export default {
  name: 'IngresoComponent',
  data: function () {
    return {
      usuario: '',
      loading: false,
      password: '',
      country: null,
      countries: [
        'Argentina',
        'Calatin',
        'Colombia',
        'México',
        'Uruguay'
      ],
      route: route,
      enable_login: false,
      error_message: null,
    }
  },
  components: {
    FooterCopyright
  },
  methods: {
    setCountry() {
      if (this.loading) return;
      if (!this.country) {
        this.error_message = "Seleccione el país";
        return;
      }
      this.error_message = '';
      this.loading = true;
      axios.post(
          '/auth/country',
          {
            "country": this.normalizeText(this.country).toUpperCase(),
            "domain": process.env.VUE_APP_DOMAIN
          },
      ).then(async (res) => {
        if (res.status === 200) return res.data;
        else throw new Error(res.data.message);
      }).then(async (data) => {
        sessionStorage.setItem('mccain_service_country', JSON.stringify(data.country));
        this.enable_login = true;
        this.error_message = '';
        this.loading = false;
      }).catch((error) => {
        if (error.status && error.status === 200) this.error_message = error.message;
        else this.error_message = error;
        this.enable_login = false;
        this.loading = false;
      });
    },
    login() {
      if (this.loading) return;
      this.error_message = '';
      this.loading = true;
      axios.post(
          '/auth/signin',
          {
            "principal": this.usuario,
            "password": this.password,
            "country": this.country
          },
      ).then(async (res) => {
        if (res.status === 200 && res.data.status === 'success') return res.data.data;
        else throw new Error(res.data.message);
      }).then(async (data) => {
        if (data.status.toString() === 'error') throw new Error(data.message);
        sessionStorage.setItem('mccain_service_user_profile', JSON.stringify(data.profile));
        const default_language = data.profile.default_language ?? 'spanish';
        this.$cookies.set("mccain_service_user_language", default_language);
        this.setLanguage(default_language);
        this.setSessionExpiration(2);
        this.$route.query.redirect
            ? this.$router.push(this.$route.query.redirect)
            : this.$router.push('/');
      }).catch((error) => {
        if (error.status && error.status === 200) this.error_message = error.message;
        else this.error_message = error;
        this.loading = false;
      });
    },
    normalizeText(text) {
      return text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    setSessionExpiration(hours) {
      const sessionDuration = hours * 60 * 60 * 1000; // Convert hours to milliseconds
      const expireTime = new Date().getTime() + sessionDuration;
      sessionStorage.setItem('mccain_service_expire_session', JSON.stringify(expireTime));
    }
  }
}
</script>